@import "../../App.scss";

.login_page {
  height: 100vh;
  .sidebanner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login_banner {
    // height: 100vh;
    width: 80%;
    // object-fit: cover;
  }

  .login_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .head_title {
      background: $theme_color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      font-size: 42px;
      border-bottom: 3px solid $log_border_color;
      width: fit-content;
      margin-bottom: 2rem;
    }

    .login_tabing {
      margin-bottom: 2rem;
      display: flex;
      gap: 15px;

      a {
        text-decoration: none;
        color: #4e4e4e;
        border-bottom: 2px solid #fff;
        font-size: 20px;
        font-weight: 400;
        padding: 0.4rem 1rem;
        display: flex;
        align-items: center;
        gap: 10px;
        // text-transform: uppercase;
        position: relative;
        border-radius: 5px 5px 0px 0px;
        overflow: hidden;

        &.active {
          border-bottom-color: $log_border_color;
          background: linear-gradient(
            86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          &::after {
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            background: linear-gradient(
              86.33deg,
              #18bad424 1.51%,
              #31b68024 52.02%,
              #80c24226 99.48%
            );
            left: 0;
          }

          svg {
            color: $log_border_color;
          }
        }
      }

      svg {
        font-size: 1.5rem;
      }
    }

    .log_detail {
      width: 50%;

      .label_with_icon {
        display: flex;
        align-items: center;
        color: $theme2;
        font-size: 20px;
        font-weight: 400;
      }

      .form-control {
        border: 1px solid $log_border_color;
      }

      .eye {
        cursor: pointer;
        position: absolute;
        right: 16px;
      }

      .forgot {
        color: rgba(96, 96, 96, 0.6);
        text-decoration: none;
      }

      .theme_btn {
        margin-top: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .login_page {
    .login_box {
      .log_detail {
        width: 90%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .login_page {
    .sidebanner {
      display: none;
    }

    .login_box {
      border: 1px dashed #67c587;
      border-radius: 0.375rem;
      padding: 1rem;

      .log_detail {
        width: 100%;

        .head_title {
          font-size: 30px;
          margin-bottom: 2.5rem;
        }

        .theme_btn {
          margin-top: 2.5rem;
        }

        .label_with_icon {
          font-size: 15px;
        }

        .forgot {
          font-size: 14px;
        }

        .form-control {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .login_page {
    .sidebanner {
      display: none;
    }

    .login_box {
      border: 1px dashed #67c587;
      border-radius: 0.375rem;
      padding: 1rem;

      .log_detail {
        width: 100%;

        .head_title {
          font-size: 30px;
          margin-bottom: 2.5rem;
        }

        .theme_btn {
          margin-top: 2.5rem;
        }

        .label_with_icon {
          font-size: 15px;
        }

        .forgot {
          font-size: 14px;
        }

        .form-control {
          font-size: 14px;
        }
      }
    }
  }
}

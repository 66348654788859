@import "../../../../App.scss";

.profile-page {
  padding: 1.5rem 0;
  background-color: #fff;
  .profile-bg {
    position: relative;
    .pr-bg {
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .upload_img_label {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: white;
      border-radius: 25px;
      padding: 3px;
    }
  }
}

.parent-profile-section {
  background-color: #fff;
  position: relative;
  .profile-img {
    display: flex;
    align-items: center;
    gap: 25px;
    .user-img-block {
      position: relative;
      .user-img {
        margin-top: -80px;
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 10px solid white;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .upload_img_label {
        cursor: pointer;
      }
      .camera-img {
        position: absolute;
        right: 10px;
        bottom: 50px;
      }
    }
  }
  .profile-tab {
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 20px;
    flex-direction: column;

    .alltabcontent {
      .tab_panel {
        .card {
          border-radius: 10px;
          border: 0;
          box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
        }
      }
    }

    .tab_group .tabs_link.Mui-selected {
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .tabs_link {
      font-weight: 500;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.58);
      text-transform: capitalize;
      padding: 0;
    }
    .MuiTabs-indicator {
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
    }
    .MuiTabs-flexContainer {
      gap: 40px;
    }
  }
}

@media (max-width: 768px) {
  .profile-page .profile-bg img {
    width: 100%;
    height: 200px;
  }
  .profile-section .profile-img .user-img-block .user-img {
    width: 170px;
    height: 170px;
  }
}

@media (max-width: 576px) {
  .profile-tab {
    .MuiTabs-flexContainer {
      max-width: 530px;
      overflow: scroll;
      gap: 18px;
    }
  }
}

@media (max-width: 576px) {
  .profile-tab {
    .MuiTabs-flexContainer {
      max-width: 350px;
      overflow: scroll;
      gap: 18px;
    }
  }
}

@import "../../../../../../App.scss";

.ProductSubCategories {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;

      .form-group {
        h4 {
          margin-bottom: 0;
        }

        .toggle_button {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Responsive Media Query */

/* Max-width 1200PX */
@media (max-width: 1200px) {
  .ProductCategories .categories_card .card .card-body {
    padding: 2rem 1.5rem;
  }
  .ProductCategories .categories_create_form .card .card-body {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .categories_create_form {
    padding-top: 30px;
  }
}

@import "../../../../App.scss";

.makepayment-page {
  padding: 1.5rem 0;
  background-color: #f9f9f9;
  .hed_top {
    .form-group {
      .form-select {
        min-width: 200px;
      }
    }
  }
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
  }
}
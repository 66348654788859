@import "../../../../App.scss";

.parent_list {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .productTitle {
    font-size: 30px;
    font-weight: 700;
  }

  .white-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-top: 2rem;
  }
}

.schedule-view {
  background-color: #f9f9f9;
  padding: 1.5rem 0;
  .main_head_box {
    display: flex;
    justify-content: space-between;
  }
  .title {
    margin-bottom: 1.5rem;
    h2 {
      color: #1f1f1f;
      font-size: 19px;
      font-weight: 500;
      border: 1px solid transparent;
      border-radius: 5px;
    }
  }

  .card {
    padding: 0;

    .fc-media-screen {
      .fc-header-toolbar {
        padding: 1rem;
        margin: 0;

        .fc-toolbar-chunk {
          h2 {
            color: var(--theme-text-color);
            font-size: 1.3rem;
            margin: 0px;
            letter-spacing: 0 !important;
            font-weight: 500;
          }

          .fc-today-button {
            background: linear-gradient(
              86.33deg,
              #18bad4 1.51%,
              #31b680 52.02%,
              #80c242 99.48%
            );
            border: 0;
            color: #fff;
            font-size: 19px;
            padding: 0.3rem 1rem;
            line-height: initial;
            border-radius: 6px;
            font-weight: 500;
            text-transform: capitalize;
            opacity: 1;

            &:disabled {
              opacity: 0.65;
              background: linear-gradient(
                86.33deg,
                #18bad4 1.51%,
                #31b680 52.02%,
                #80c242 99.48%
              );
            }

            &:focus {
              box-shadow: none;
            }
          }

          .fc-button-group {
            .fc-button {
              background-color: #1f1f1f;
              padding: 0.2rem;
              border-radius: 10px;
              margin: 0;

              &:focus {
                box-shadow: none;
              }

              &.fc-prev-button {
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
              }

              &.fc-next-button {
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
              }

              .fc-icon {
                font-size: 1.5rem;
                line-height: normal;
              }
            }
          }
        }
      }

      .fc-view-harness {
        .fc-daygrid {
          table {
            thead {
              tr {
                th {
                  padding: 0 !important;

                  .fc-col-header {
                    thead {
                      background-color: #fafafa;

                      tr {
                        th {
                          padding: 1rem !important;

                          .fc-scrollgrid-sync-inner {
                            a {
                              padding: 0 !important;
                              font-size: 16px;
                              font-weight: 500;
                              color: #344563;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 0rem !important;

                  .fc-daygrid-body {
                    tbody {
                      tr {
                        td {
                          padding: 1rem !important;

                          .fc-daygrid-day-frame {
                            padding: 0;
                            border: 0;

                            .fc-daygrid-day-events {
                              .fc-daygrid-event-harness {
                                margin-bottom: 0.5rem;
                                &:last-child {
                                  margin-bottom: 0;
                                }
                                a {
                                  margin-bottom: 0 !important;
                                  border: 0 !important;
                                  border-radius: 10px !important;
                                  padding: 0.5rem !important;
                                  font-size: 16px;
                                  cursor: pointer;
                                  .em_detail {
                                    line-height: 30px;

                                    h5 {
                                      color: #fff;
                                      margin: 0;
                                      font: inherit;
                                      font-weight: 400;
                                      b {
                                        font-weight: 500;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../../../App.scss";

.invoice_list_page {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
  .loader_button {
    background: linear-gradient(
      86.33deg,
      #18bad4 1.51%,
      #31b680 52.02%,
      #80c242 99.48%
    );
    border: 1px solid #31b680;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    line-height: 6px;
    color: #fff !important;
    text-align: center;
    padding: 5px 8px;
    display: flex;
    align-items: center;
  }
}

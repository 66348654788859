@import "../../../../../App.scss";

.customer_listpage{
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
  }
  .product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }
}


.toggle_open_data {
  .open_box {
    border-radius: 12px;

    .popup_detail_box {
      h6 {
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        text-transform: capitalize;
        color: #4e4e4e;
        padding: 1.5rem 1rem;
        border-bottom: 1px dashed gainsboro;
        margin-bottom: 0;
        text-align: center;
      }

      .col-md-12 {
        border-bottom: 1px dashed gainsboro;
      }

      .popup_detail {
        padding: 1.5rem;
        text-align: center;

        .delete_text {
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.58);
          margin-bottom: 2rem;

          span {
            color: $red;
          }
        }

        .popup_btn {
          border-radius: 5px;
          text-transform: uppercase;
          font-size: 17px;
          font-weight: 600;
          padding: 0.5rem 1rem;
          margin: 0 0.9rem;

          &.cancel {
            border: 1px solid $red;
            color: $red;
            background: #ffffff;
          }

          &.save {
            border: 1px solid $theme1;
            color: #ffffff;
            background-color: $theme1;
          }
        }
      }
    }
  }
}
.dailySalesReport-page {

  .report-table {
      &.cash-border{
        border-top: 1px dashed black;
        border-bottom: 1px dashed black;
        padding: 10px 0 4px;
        margin: 0 0 15px;
      }
      &.cash-border1{
        border-top: none;
        border-bottom: 1px dashed black;
        padding: 0 0 4px;
        margin: 0 0 15px;
      }
      .title-width-bill{
        width: 20%;
      }
    }

  }


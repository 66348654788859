@import "../../../../../App.scss";

.listdata_control_sec {
  align-items: center;

  .form-select {
    &:focus {
      box-shadow: none;
    }
  }

  .search_sec {
    .search_input {
      position: relative;
      align-items: center;
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      .icon {
        position: absolute;
        left: 1.5rem;
        font-size: 25px;
        color: gray;
      }

      img {
        position: absolute;
        left: 10px;
      }

      input {
        background-color: rgba(238, 238, 238, 0.94);
        padding-left: 4rem !important;
        border: 0;
        border-radius: 5px;
        padding: 0.6rem 1rem;
        line-height: inherit;
        min-width: 350px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .detail_button {
    margin-left: 1rem;
  }
}

.Emp_hour_all_list {
  .table-box {
    box-shadow: none !important;
    border: 0px;
    // border-radius: 10px;
    // overflow: hidden;
    white-space: nowrap;

    thead {
      tr {
        // background: $theme_color;

        th {
          background: transparent !important;
          border: 0px;
          padding: 10px 15px;
          color: $text_color;
          // color: #fff;
          font-size: 16px;
          .svg_hide {
            svg {
              display: none;
            }
          }
          // .MuiTableSortLabel-root {
          //   &:hover {
          //     color: #fff;
          //   }
          // }
        }
      }
    }

    tbody {
      tr {
        border: 1px solid rgba(217, 217, 217, 0.33);

        &:nth-of-type(even) {
          background-color: #fff !important;
        }

        td {
          border: 0px !important;
          padding: 10px 15px;
          color: #606060;
          font-size: 14px;
          font-family: inherit;
          // font-weight: 500;

          &:first-child {
            // white-space: pre-wrap;
          }

          .icon_td {
            display: flex;
            align-items: center;

            .menu_icon {
              font-size: 1.4rem !important;
              margin-right: 7px;
              width: 2rem;
              color: #18212c;
            }
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
        .product_name {
          width: 240px;
          max-width: 240px;
          // word-wrap: break-word;
          white-space: break-spaces;
        }
      }
    }
  }
  .personal_detail_label {
    display: block;
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: -15px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: $text_color;
      //   border: 1px solid $Green;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input ~ .checkmark {
      background-color: #fff;
      border: 1px solid $text_color;
    }

    input:checked ~ .checkmark {
      background: $theme3;
      border: 1px solid $theme3;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
  .edit_delete {
    display: flex;
    align-items: center;
  }

  .btn_edit {
    border-radius: 5px;
    padding: 0 0.5rem;
    background: #fff;
    font-size: 20px;
    color: #606060;
    border: 0;
    line-height: 18px;

    svg {
      color: $theme3;
    }

    &.text {
      font-size: 14px;
      color: $theme3;
    }
  }

  .btn_dlt {
    border-radius: 5px;
    padding: 0 0.5rem;
    background: #fff;
    font-size: 20px;
    color: #606060;
    border: 0;
    line-height: 18px;

    .icon {
      color: $theme3;
    }
  }

  .er_nodata {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 2rem;
    color: $text_color;
  }

  .page_nation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem 1.5rem;
    background-color: #cdcdcd5e;

    .rowsPerPageOptions {
      .p_18_text {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: #606060;
      }

      .pagination_select {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin: 0 10px;
        color: #4d4d4d;
        background-color: #fff;
        padding: 2px 5px;

        &:focus-visible {
          outline: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .listdata_control_sec {
    flex-direction: column;

    .title {
      width: 100%;
      margin-bottom: 10px;
    }

    .form-select {
      width: 100% !important;
      margin-bottom: 10px;
    }

    .search_sec {
      width: 100%;
    }

    .detail_button {
      margin-left: 0rem;
      margin-top: 1rem;
      width: 100%;
    }
  }

  .Emp_hour_all_list {
    .page_nation {
      flex-direction: column;
    }
  }
}

@import "../../../../App.scss";

.FamilyIncomeSurvey {
    padding: 1.5rem 0;
    background-color: $main_backgroundColor;

    .table {
        thead {
            th {
                vertical-align: middle;
                text-align: center;
                font-weight: 500;
            }
        }

        tbody {
            td {
                text-align: center;
                vertical-align: middle;
                font-weight: 400;

                .form-control {
                    min-width: 150px;
                }

                .form-select {
                    min-width: 150px;
                }

                button {
                    min-width: fit-content;
                    padding: 0.5rem !important;
                }

                .radioBtn {
                    .MuiRadio-root {
                        padding: 5px 9px;
                    }
                }
            }
        }
    }

    .card {
        border-radius: 10px;
        border: 0;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
        width: 70%;
        margin: auto;

        .hed {
            .info {
                margin-top: 1rem;

                // p {
                //     margin: 0;
                //     font-size: 15px;
                // }
            }
        }

        .houseHoldForm {
            .partA {
                ul {
                    padding: 0;
                    list-style: none;
                    margin: 0;
                    display: flex;
                    gap: 0 2rem;
                    margin-bottom: 0.5rem;

                    &:first-child {
                        margin-bottom: 1rem;
                    }

                    li {
                        width: 100%;

                        button {
                            margin: 0;
                            padding: 0.2rem 0.3rem !important;
                            border: 0 !important;
                            min-width: fit-content;

                            .icon {
                                font-size: 20px;
                            }

                            &.cancel_btn {
                                background: gainsboro;
                                color: inherit;
                            }
                        }

                    }
                }
            }

            .partB {

                ul {
                    // padding: 0;
                    list-style: decimal;
                    line-height: 30px;
                }

                .form {
                    .input_div {
                        label {
                            margin-bottom: 0.5rem;
                        }

                        margin-bottom: 1rem;
                    }
                }
            }

            .partC {
                // margin-top: 2rem;

                ul {
                    // padding: 0;
                    list-style: decimal;
                    line-height: 30px;
                }
            }

            .partD {
                .form {
                    .input_div {
                        label {
                            margin-bottom: 0.5rem;
                        }

                        margin-bottom: 1rem;
                    }
                }
            }

            .footer {
                text-align: end;

                p {
                    text-align: left;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .FamilyIncomeSurvey {
        .card {
            width: 90%;
            .houseHoldForm{
                .table-responsive {
                    margin-bottom: 1.5rem;
                    &::-webkit-scrollbar {
                        display: block;
                        background-color: gainsboro;
                        height: 10px;
                        border-radius: 3px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: darkgray;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .FamilyIncomeSurvey {
        .card {
            width: 90%;
            .houseHoldForm{
                .table-responsive {
                    margin-bottom: 1.5rem;

                    table {
                        thead {
                            th {
                                font-size: 14px;
                            }
                        }

                        tbody {
                            td {
                                font-size: 14px;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        display: block;
                        background-color: gainsboro;
                        height: 10px;
                        border-radius: 3px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: darkgray;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .FamilyIncomeSurvey {
        padding: 0;

        .card {
            border-radius: 0px;
            border: 0;
            box-shadow: none;
            width: 100%;
            padding: 1.5rem;

            .hed {
                .d-flex {
                    flex-direction: column;
                    gap: 10px;

                    img {
                        margin: auto;
                    }

                    h2 {
                        font-size: 1.1rem;
                    }
                }
            }

            .houseHoldForm {
                .partB {
                    .benifits {
                        .benifits_checkbox {
                            font-size: 14px;
                            width: 100%;
                            margin: 5px 0;

                            span {
                                &.MuiCheckbox-root {
                                    padding: 0 2px 0 0px;
                                }

                                &.MuiFormControlLabel-label {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .partC {
                    h5 {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .footer {
                    text-align: center;
                }

                .table-responsive {
                    margin-bottom: 1.5rem;

                    table {
                        thead {
                            th {
                                font-size: 14px;
                            }
                        }

                        tbody {
                            td {
                                font-size: 14px;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        display: block;
                        background-color: gainsboro;
                        height: 10px;
                        border-radius: 3px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: darkgray;
                        border-radius: 3px;
                    }
                }
            }

            p {
                font-size: 14px;
            }
        }
    }
}
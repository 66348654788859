@import "../../../App.scss";

.toggle_img_Show {
  .delete_data_box {
    max-height: 80%;
    overflow-x: auto;
    justify-content: start;
    padding: 1rem;
    border-radius: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
      border-radius: 20px;
      // width: 10px;
    }

    .box {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 1rem;
      border: 1px dashed gainsboro;
      border-radius: 10px;
    }

    button {
      &:hover {
        background-color: #009688;
      }
    }
  }
}

@import "../../../../../App.scss";

.user_list {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
}

.districtList_box {
  border-radius: 12px;

  .districtList_details {
    h6 {
      font-weight: 700;
      font-size: 28px;
      line-height: 28px;
      text-transform: capitalize;
      color: #4e4e4e;
      padding: 1.5rem 1rem;
      border-bottom: 1px dashed gainsboro;
      margin-bottom: 0;
      text-align: center;
    }

    .inner_sec {
      padding: 1.5rem;
      
      .popup_btn {
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        padding: 0.5rem 2rem;
        margin: 0 0.5rem;

        &.cancel {
          border: 1px solid $red;
          color: $red;
          background: #ffffff;
        }

        &.delete {
          border: 0;
          color: #ffffff;
          background-color: $red;
        }
      }
    }
  }
}
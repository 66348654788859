.toggle_img_Show {
  .img_show {
    width: 100%;
    text-align: center;
    height: 400px;
    img {
      height: 100%;
    }
  }
  .delete_data_box {
    button {
      &:hover {
        background-color: #fff;
      }
    }
  }
}

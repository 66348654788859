@import "../../../../../../App.scss";

.create_smtp {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;
      .eye {
        cursor: pointer;
        position: absolute;
        right: 16px;
      }
    }
  }
}

@import "../../../../../App.scss";

.product_listpage {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .product_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .listing-product .all_list .table-box tbody tr td:first-child {
    min-width: 350px;
    white-space: pre-wrap;
  }
}

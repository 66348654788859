@import "../../../../../../App.scss";

.create_product {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .tab_group {
    .tabs_link {
      font-weight: 400;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.58);
      text-transform: capitalize;

      &.Mui-selected {
        background: linear-gradient(
          86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .MuiTabs-indicator {
      background: $theme_color;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      .tab_panel {
        .form-group {
          .form-control {
            border: 1px solid #3f425436;
            border-radius: 8.09942px;
            color: rgba(63, 66, 84, 0.9);
            font-size: 15px;
            font-weight: 500;
            font-family: inherit;
          }
        }
      }
      .radio_button {
        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #666;
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 1px solid $theme3;
          border-radius: 100%;
          background: #fff;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          content: "";
          width: 12px;
          height: 12px;
          background: $theme3;
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        [type="radio"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        [type="radio"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      .personal_detail_label {
        position: relative;
        padding-left: 35px;
        // margin-bottom: 20px;
        cursor: pointer;
        color: $text_color2;
        font-weight: 400;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          // position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #fff;
          //   border: 1px solid $Green;
          border-radius: 6px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          &:after {
            left: 6px;
            top: 1px;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        input ~ .checkmark {
          background-color: #fff;
          border: 1px solid $theme3;
        }

        input:checked ~ .checkmark {
          background: $theme_color;
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }
      .upload_img {
        display: flex;
        position: relative;
        align-items: center;
        // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
        // border-radius: 15px !important;
        overflow: hidden;
        .form-control {
          border-radius: 8px 0px 0px 8px !important;
        }
        label {
          cursor: pointer;
          padding: 0.32rem 1rem !important;
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          border: 0;
          border-radius: 0px 8px 8px 0px !important;
          text-align: center;
          img {
            max-width: 80%;
          }
        }
      }
    }
  }
}

.edit_delete {
  justify-content: center;
}

.modal-pop {
  .allergen-popup {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    width: 724px;
    padding: 80px 50px;

    img {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    input {
      border: 1px solid #3f4254;
      border-radius: 6px;
    }
  }
}
.toggle_delete_data {
  .delete_data_box {
    border-radius: 12px;

    .popup_delete_detail_box {
      h6 {
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        text-transform: capitalize;
        color: #4e4e4e;
        padding: 1.5rem 1rem;
        border-bottom: 1px dashed gainsboro;
        margin-bottom: 0;
        text-align: center;
      }

      .popup_delete_detail {
        padding: 1.5rem;
        text-align: center;

        .delete_text {
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.58);
          margin-bottom: 2rem;

          span {
            color: $red;
          }
        }

        .popup_btn {
          border-radius: 5px;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 600;
          padding: 0.5rem 2rem;
          margin: 0 0.5rem;

          &.cancel {
            border: 1px solid $red;
            color: $red;
            background: #ffffff;
          }

          &.delete {
            border: 0;
            color: #ffffff;
            background-color: $red;
          }
        }
      }
    }
  }
}
/* Responsive Media Query */

/* Max-width 576PX */
@media (max-width: 768px) {
  .modal-pop {
    .allergen-popup {
      width: 300px;
      padding: 50px 20px;
    }
  }
}

/* Max-width 576PX */
@media (max-width: 576px) {
  .create_product .tab_group .tabs_link {
    font-size: 18px;
    padding: 0 10px;
  }
}

.hourTab-page {
  background: #fff;
  padding: 1rem;
  border-radius: 5px;

  h5 {
    color: #3f4254;
    font-size: 20px;
    margin-bottom: 5px;
  }

  p {
    color: #000;
    font-size: 16px;
  }

  h6 {
    font-size: 18px;
  }

  .border-dash {
    border-bottom: 1px dashed #000;
  }

  .report-table {
    padding-top: 30px;

    .table-box {
      margin-bottom: 6px;
      &.cash-border{
        border-top: 1px dashed black;
        border-bottom: 1px dashed black;
        padding: 10px 0 4px;
        margin: 0 0 15px;
      }
    }

    .endreport-border{
      border-top: 1px dashed #000;
      padding-top: 15px;
      margin-top: 15px;
    }
  }
}

@import "../../../../App.scss";

.createUserProfile {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .user-profile {
      margin: 20px 0;
      position: relative;
      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
      .edit-icon {
        img {
          width: 53px;
          height: 53px;
          position: absolute;
          bottom: 9px;
          left: 115px;
        }
      }
    }
  }
}
.crop_box {
  .set_crop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000000ab;
    .set_crop_d {
      position: relative;
      top: 50px;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .reactEasyCrop_Container {
      position: relative;
      height: 80%;
      width: 75%;
      margin: 0 auto;
      background: #fff;
    }

    .Crop_hed {
      background-color: #fff;
      padding: 1rem;
    }

    .bottom_btn {
      background-color: #fff;
      padding: 1rem;

      button {
        margin: auto 10px auto 0;
      }
    }
  }
}
#file-input {
  display: none;
}

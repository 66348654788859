@import "../../../../../App.scss";

.parent-household-section {
    margin: 50px 0;

    .margin-bottom {
        margin-bottom: 20px;
    }

    .parent-household-box {
        border: 1px solid var(--map-color-breckfast, #67C587);
        border-left: 10.806px solid var(--map-color-breckfast, #67C587);
        padding: 2rem 2rem;
        border-radius: 10px;

        .user-title {
            h3 {
                text-transform: capitalize;
                color: #4CAF50;
                font-weight: 600;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-top: 20px;
            margin-bottom: 0;

            li {
                display: flex;
                align-items: flex-start;
                padding: 1rem 0;
                border-bottom: 1px dashed gainsboro;

                &:last-child {
                    border: 0;
                    padding-bottom: 0;
                }

                p {
                    color: #000;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    width: 50%;
                    margin: 0;
                }

                span {
                    color: rgba(0, 0, 0, 0.58);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .household-section .household-box {
        padding: 20px;
    }

    .first-block {
        padding-bottom: 30px;
    }
}


@media (max-width: 576px) {
    .household-section .household-box ul li p {
        min-width: 100px;
    }
}
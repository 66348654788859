@import "../../../../../App.scss";

.student_deatils_modal {
  .pagi_nation_survey {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem 1.5rem;
    background-color: #cdcdcd5e;

    .rowsPerPageOptions {
      .p_18_text {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: #606060;
      }

      .pagination_select {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin: 0 10px;
        color: #4d4d4d;
        background-color: #fff;
        padding: 2px 5px;

        &:focus-visible {
          outline: 0;
        }
      }
    }
    ul {
      gap: 0rem !important;
    }
  }
  .er_nodata {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 2rem;
    color: $text_color;
  }
}

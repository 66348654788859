@import "../../../../../../App.scss";

.createprimarytab-page {
  padding: 1.5rem 0;
  background-color: #f9f9f9;
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .radio_button {
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid $theme3;
        border-radius: 100%;
        background: #fff;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 12px;
        height: 12px;
        background: $theme3;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
    .upload_img {
      display: flex;
      position: relative;
      align-items: center;
      // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      // border-radius: 15px !important;
      overflow: hidden;
      .form-control {
        border-radius: 6px 0px 0px 6px !important;
      }
      label {
        cursor: pointer;
        padding: 0.32rem 1rem !important;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
        border: 0;
        border-radius: 0px 6px 6px 0px !important;
        text-align: center;
        img {
          max-width: 80%;
        }
      }
    }
  }
}

@import "../../../App.scss";

.toggle_delete_data {
  .delete_data_box {
    border-radius: 12px;

    .popup_delete_detail_box {
      h6 {
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        text-transform: capitalize;
        color: #4e4e4e;
        padding: 1.5rem 1rem;
        border-bottom: 1px dashed gainsboro;
        margin-bottom: 0;
        text-align: center;
      }

      .popup_delete_detail {
        padding: 1.5rem;
        text-align: center;

        .delete_text {
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.58);
          margin-bottom: 2rem;

          span {
            color: $red;
          }
        }

        .popup_btn {
          border-radius: 5px;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 600;
          padding: 0.5rem 2rem;
          margin: 0 0.5rem;

          &.cancel {
            border: 1px solid $red;
            color: $red;
            background: #ffffff;
          }

          &.delete {
            border: 0;
            color: #ffffff;
            background-color: $red;
          }
        }
      }
      .personal_detail_label {
        display: block;
        position: relative;
        padding-left: 35px;
        /* margin-bottom: 20px; */
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        -webkit-user-select: none;
        user-select: none;
        color: #7f7e83;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 4px;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #fff;
          color: $text_color;
          //   border: 1px solid $Green;
          border-radius: 3px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          &:after {
            left: 5px;
            top: 1px;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        input ~ .checkmark {
          background-color: #fff;
          border: 1px solid $text_color;
        }

        input:checked ~ .checkmark {
          background: $theme3;
          border: 1px solid $theme3;
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }
    }
  }
}

.dailySalesReport-page {
  .billing-details {
    margin-top: 20px;
    border-bottom: 1px dashed #000;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .report-table {

    .billing-table{
      border-top: 1px dashed #000;
      border-bottom: 1px dashed #000;
      padding: 15px 0;
      margin: 15px 0;
    }
  }
}

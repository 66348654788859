@import "./Components/Common/common.scss";
* {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Readex Pro", sans-serif !important;
  font-family: "Readex Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    background-color: #f4f4f4;
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      86.33deg,
      #18bad4 1.51%,
      #31b680 52.02%,
      #80c242 99.48%
    );
    border-radius: 5px;
  }
}
a {
  text-decoration: none !important;
}
.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free" !important;
}
html {
  background-color: #f9f9f9;
  font-family: "Readex Pro", sans-serif;
}
body {
  background-color: #f9f9f9;
  font-family: "Readex Pro", sans-serif;
}

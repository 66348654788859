@import "../../../../App.scss";

.upload_list {
  padding: 1.5rem 0;
  background-color: #f9f9f9;
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
  }
  .box_name {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }
  .personal-detail-label {
    display: block;
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    color: #7f7e83;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      color: $text_color;
      //   border: 1px solid $Green;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 1px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input ~ .checkmark {
      background-color: #fff;
      border: 1px solid $text_color;
    }

    input:checked ~ .checkmark {
      background: $theme3;
      border: 1px solid $theme3;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  .upload_img {
    display: flex;
    position: relative;
    overflow: hidden;

    .upload_box {
      border-radius: 8px 0px 0px 8px !important;
    }

    label {
      cursor: pointer;
      padding: 0.32rem 1rem !important;
      background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
      border: 0;
      border-radius: 0px 8px 8px 0px !important;
      text-align: center;
      img {
        max-width: 80%;
      }
    }
  }

  .eye {
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
}

@import "../../../../../../App.scss";

.create_student_new {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;
  .main_wrapper {
    .card {
      border-radius: 10px;
      border: 0;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
      padding: 2rem 2.5rem;

      .card-body {

        .profile-picture-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
          .profile-picture {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: #d3d3d3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #666;
            border: 2px solid #e0e0e0;
            cursor: pointer;
            overflow: hidden;
          }

          .profile-picture img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .caption {
            margin-top: 10px;
            font-size: 14px;
            color: #333;
          }
        }
        .barcode_img {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 35px;
        }
        .student_title {
          color: rgba(63, 66, 84, 0.9019607843);
          padding: 15px;
          border-radius: 8px;
          margin-bottom: 20px;
          background: gainsboro;
        }
        .drag_drop {
          border: 2px dashed #ccc;
          border-radius: 8px;
          padding: 1.5rem;
          text-align: center;
          cursor: pointer;
          margin-top: 20px;
          .default_text_dnd {
            border: none;
            &::placeholder {
              display: flex;
              justify-content: center;
              text-align: center;
              color: $text_color;
            }
          }
        }
      }
    }
  }
}

@import "../../../../../App.scss";

.create_meals {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;

      .checkbox_meal {
        .form-group {
          margin: 0;
          margin-bottom: 1rem;
          margin-top: auto;
          padding: 0.5rem 1rem;
          border-radius: 8px;
          border: 1px solid rgba(63, 66, 84, 0.2117647059);

          .personal_detail_label {
            margin: 0;

            .checkmark {
              top: 2px;
            }
          }
        }
      }

      .upload_img {
        display: flex;
        position: relative;
        align-items: center;
        // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
        // border-radius: 15px !important;
        overflow: hidden;

        .form-control {
          border-radius: 8px 0px 0px 8px !important;
        }

        label {
          cursor: pointer;
          padding: 0.32rem 1rem !important;
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          border: 0;
          border-radius: 0px 8px 8px 0px !important;
          text-align: center;

          img {
            max-width: 80%;
          }
        }
      }

      .form-neselect {
        .select__menu {
          // position: relative;
          z-index: 9;
        }

        .select__control {
          border-radius: 8px;
          box-shadow: none;
          font-size: 15px;

          .select__value-container {
            padding: 0.7rem 1rem;

            &.select__value-container--has-value {
              padding: 7px 10px;
            }

            .select__input-container {
              padding: 0;
              margin: 0;
            }
          }

          .select__indicators {
            .select__indicator-separator {
              margin: 0;
            }
          }
        }
      }

      .personal_detail_label {
        // display: block;
        position: relative;
        padding-left: 35px;
        // margin-bottom: 20px;
        cursor: pointer;
        color: $text_color2;
        font-weight: 400;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          // position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #fff;
          //   border: 1px solid $Green;
          border-radius: 6px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          &:after {
            left: 6px;
            top: 1px;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        input ~ .checkmark {
          background-color: #fff;
          border: 1px solid $theme3;
        }

        input:checked ~ .checkmark {
          background: $theme_color;
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }

      thead {
        tr {
          background: $theme_color;

          th {
            background: transparent !important;
            border: 0px;
            padding: 12px 15px;
            color: #fff;
            font-size: 16px;

            .MuiTableSortLabel-root {
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }

      tbody {
        tr {
          border: 1px solid rgba(217, 217, 217, 0.33);

          &:nth-of-type(even) {
            background-color: #fff !important;
          }

          td {
            border: 0px !important;
            padding: 10px 15px;
            color: #606060;
            font-size: 14px;
            font-family: inherit;
            font-weight: 500;

            &:first-child {
              // white-space: pre-wrap;
            }

            .icon_td {
              display: flex;
              align-items: center;

              .menu_icon {
                font-size: 1.4rem !important;
                margin-right: 7px;
                width: 2rem;
                color: #18212c;
              }
            }

            a {
              text-decoration: none;
              color: inherit;
            }
          }

          .product_name {
            width: 240px;
            max-width: 240px;
            // word-wrap: break-word;
            white-space: break-spaces;
          }
        }
      }

      .table_fix {
        max-height: 600px;
        overflow-y: auto;

        .head_fix {
          position: sticky;
          top: 0;
          z-index: 8;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $theme3;
          border-radius: 20px;
        }
      }

      .form-group {
        h4 {
          margin-bottom: 0;
        }

        .toggle_button {
          margin-bottom: 0;
        }
      }

      .page_nation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.6rem 1.5rem;
        background-color: #cdcdcd5e;

        .rowsPerPageOptions {
          .p_18_text {
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            color: #606060;
          }

          .pagination_select {
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            border: 1px solid #eeeeee;
            border-radius: 5px;
            margin: 0 10px;
            color: #4d4d4d;
            background-color: #fff;
            padding: 2px 5px;

            &:focus-visible {
              outline: 0;
            }
          }
        }
      }
    }
  }
}

@import "../../../../../App.scss";

.household-section{
    margin: 50px 0;
    .margin-bottom{margin-bottom: 20px;}
    .household-box{
        border: 1px solid var(--map-color-breckfast, #67C587);
        border-left: 10.806px solid var(--map-color-breckfast, #67C587);
        padding: 20px 50px;

        .user-profile-block{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            img{
                width: 140px;
                height: 140px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        ul{
            list-style: none;
            padding-left: 0;
            margin-top: 20px;
            li{
                display: flex;
                align-items: flex-start;
                padding-top: 20px;
                p{
                    color: #000;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    min-width: 120px;
                }
                span{
                    color: rgba(0, 0, 0, 0.58);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media (max-width: 991px){
    .household-section .household-box{
        padding: 20px;
    }
    .first-block{
        padding-bottom: 30px;
    }
}


@media (max-width: 576px){
    .household-section .household-box ul li p{
        min-width: 100px;
    }
}
@import "../../../../App.scss";

.students {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .student_detail_box {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 20px;
    flex-direction: column;

    .alltabcontent {
      .tab_panel {
        .card {
          border-radius: 10px;
          border: 0;
          box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
        }
      }
    }

    .tab_group .tabs_link.Mui-selected {
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .tabs_link {
      font-weight: 500;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.58);
      text-transform: capitalize;
      padding: 0;
    }
    .MuiTabs-indicator {
      background: linear-gradient(
        86.33deg,
        #18bad4 1.51%,
        #31b680 52.02%,
        #80c242 99.48%
      );
    }
    .MuiTabs-flexContainer {
      gap: 40px;
    }
  }
}

/* responsive media query */
@media (max-width: 576px){
  .students .student_detail_box .MuiTabs-flexContainer {
    gap: 10px;
  }
  .students .student_detail_box .tabs_link{
    font-size: 16px;
  }
}
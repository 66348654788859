@import "../../../../../../App.scss";

.createSecoundTab {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
  }
}

/* Responsive Media Query */

/* Max-width 1200PX */
@media (max-width: 1200px) {}

/* Max-width 991PX */
@media (max-width: 991px) {
  .ProductTitle .tab_group {
    flex-wrap: wrap;
  }

  .ProductTitle .MuiTabs-flexContainer {
    overflow-x: scroll;
    width: auto;
    margin-bottom: 18px;
    padding-bottom: 12px;
  }

  .ProductTitle .MuiTabs-indicator {
    bottom: 23px;
  }
}

/* Max-width 768PX */
@media (max-width: 768px) {
  .ProductTitle .MuiTabs-flexContainer {
    margin-bottom: 18px;
    padding-bottom: 12px;
    white-space: nowrap;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .student-tabpage .product_box .MuiTabs-flexContainer {
    gap: 20px;
  }

  .modal-pop {
    .addlog-popup {
      width: 300px;
      padding: 20px;
    }

    .form-group {
      flex-wrap: wrap;
      gap: 0 !important;
    }
  }
}

/* Max-width 576PX */
@media (max-width: 576px) {
  .create_product .tab_group .tabs_link {
    font-size: 18px;
    padding: 0 10px;
  }

  .createprimarytab-page .title {
    margin-bottom: 30px;
  }

  .ProductTitle .MuiTabs-flexContainer {
    max-width: 545px;
  }
}
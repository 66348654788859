@import "../../../../../App.scss";

.template_listpage {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
}

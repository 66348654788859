$text_color: #000000;
$text_color2: rgba(0, 0, 0, 0.58);
$text: rgba(63, 66, 84, 0.9);
$theme_color: linear-gradient(
  86.33deg,
  #18bad4 1.51%,
  #31b680 52.02%,
  #80c242 99.48%
);
$red: #d91f2d;
$theme1: #18bad4;
$theme2: #31b680;
$theme3: #80c242;
$log_border_color: #67c587;
$input_border_color: #3f4254;
$placeholder_color: #60606066;
$main_backgroundColor: #f9f9f9;
$border_color: #edeef1;

.red-color {
  color: #d91f2d;
}

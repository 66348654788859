@import "../../../../../App.scss";

.parent_send_text_list {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  // .product_box {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-wrap: wrap;
  //   align-items: center;
  //   margin-bottom: 20px;

  //   .productTitle {
  //     font-size: 30px;
  //     font-weight: 700;
  //   }
  // }
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
  }
}

@import "../../../App.scss";

.toggle_button {
  user-select: none;

  .switch-wrapper>input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  .switch-wrapper>input[type="checkbox"]+.switch {
    transform: translateX(5px);
    background: #D9D9D9;
  }

  .switch-wrapper>input[type="checkbox"]:checked+.switch {
    transform: translateX(50%) translateX(-19px);
    background: $theme_color;
  }

  .switch-wrapper>input[type="checkbox"]:disabled+.switch {
    background: #ccc;
  }

  .switch-wrapper {
    border-radius: 20px;
    cursor: pointer;
    height: 24px;
    float: left;
    overflow: hidden;
    position: relative;
    width: 48px;
  }

  .switch-wrapper>.switch {
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    left: -100%;
    position: relative;
    transition: 100ms linear;
    width: 200%;
  }

  .switch-wrapper>.switch>.switch-handle {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    left: 50%;
    position: relative;
    // top: 3px;
    width: 14px;
    z-index: 3;
  }
}

.toggle_delete_data1 {
  .delete_data_box {
    border-radius: 12px;

    .popup_delete_detail_box {
      .popup_delete_detail {
        padding: 2rem 1rem;
        text-align: center;
        background: #f9fafc;
        border-radius: 15px;

        h5 {
          font-weight: 600;
          font-size: 48px;
          line-height: 56px;
          text-transform: capitalize;
          color: $red;
          margin-bottom: 26px;
        }

        .delete_text {
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;
          color: #4e4e4e;
          margin-bottom: 32px;

          span {
            background: linear-gradient(90deg,
                $theme1 -7.19%,
                $theme2 105.94%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .popup_btn {
          border-radius: 5px;
          font-weight: 500;
          font-size: 16px;
          text-transform: capitalize;
          padding: 0.7rem 1.5rem !important;
          margin: 0 1rem;

          &.cancel {
            border: 2px solid $theme2;
            color: $theme2;
            background: #ffffff;
          }

          &.delete {
            border: 2px solid $red;
            color: #ffffff;
            background-color: $red;
          }
        }
      }
    }
  }
}
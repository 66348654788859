@import "../../../../../App.scss";

.checkReportPage {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
    .table-box {
      box-shadow: none;
      table {
        margin-bottom: 0;
        thead {
          tr {
            th {
              vertical-align: middle;
              text-align: center;
              font-weight: 500;
            }
          }
        }

        tbody {
          tr {
            td {
              vertical-align: middle;
              text-align: center;
              font-weight: 500;
              .inner-table {
                tr {
                  td {
                    padding: 0.5rem 0.5rem;
                    border-width: 1px 1px;
                    &:last-child {
                      border-right: 0;
                    }
                    &:first-child {
                      border-left: 0;
                    }
                  }
                }
              }
              a {
                color: #80c242;
              }
              .btn_dlt {
                border-radius: 5px;
                padding: 0 0.5rem;
                background: #fff;
                font-size: 20px;
                color: #606060;
                border: 0;
                line-height: 18px;
                svg {
                  color: #80c242;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../../App.scss";

.HouseHoldMemberInformation {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .image_with_dropdow {
    margin: 0 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .table {
    thead {
      tr {
        th {
          vertical-align: middle;
          text-align: center;
          font-weight: 500;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          vertical-align: middle;
          font-weight: 400;

          .form-control {
            min-width: 150px;
          }

          .form-select {
            min-width: 150px;
          }

          button {
            min-width: fit-content;
            padding: 0.5rem !important;
          }

          .radioBtn {
            .MuiRadio-root {
              padding: 5px 9px;
            }
          }
        }
      }
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    width: 90%;
    margin: auto;

    // .language_dropdown {
    //   position: relative;
    //   .col-md-3 {
    //     position: absolute;
    //     z-index: 10;
    //     right: 20px;
    //     top: 20px;
    //   }
    // }

    .form_guideline {
      border: 1px solid gainsboro;
      padding: 1.5rem 1rem;

      .tox-editor-header {
        display: none;
      }

      .tox-edit-area {
        &::before {
          display: none;
        }
      }

      .tox-tinymce {
        border: 0 !important;
        border-radius: 0px !important;
      }
    }

    .hed {
      .info {
        margin-top: 1rem;

        p {
          margin: 0;
          font-size: 15px;
        }
      }

      .header_logo {
        // box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
        height: 120px;
        width: 120px;
      }
    }

    .houseHoldForm {
      .partA {
        ul {
          padding: 0;
          list-style: none;
          margin: 0;
          display: flex;
          gap: 0 2rem;
          margin-bottom: 0.5rem;

          &:first-child {
            margin-bottom: 1rem;
          }

          li {
            width: 100%;

            button {
              margin: 0;
              padding: 0.2rem 0.3rem !important;
              border: 0 !important;
              min-width: fit-content;

              .icon {
                font-size: 20px;
              }

              &.cancel_btn {
                background: gainsboro;
                color: inherit;
              }
            }
          }
        }

        .personal_detail_label {
          display: block;
          position: relative;
          padding-left: 35px;
          /* margin-bottom: 20px; */
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          -webkit-user-select: none;
          user-select: none;
          color: #7f7e83;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: -10px;
            left: 33%;
            height: 18px;
            width: 18px;
            background-color: #fff;
            color: $text_color;
            //   border: 1px solid $Green;
            border-radius: 3px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }

            &:after {
              left: 5px;
              top: 1px;
              width: 7px;
              height: 12px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          input~.checkmark {
            background-color: #fff;
            border: 1px solid $text_color;
          }

          input:checked~.checkmark {
            background: $theme3;
            border: 1px solid $theme3;
          }

          input:checked~.checkmark:after {
            display: block;
          }
        }
      }

      .partB {
        ul {
          // padding: 0;
          list-style: decimal;
          line-height: 30px;
        }
      }

      .partC {
        // margin-top: 2rem;

        ul {
          // padding: 0;
          list-style: decimal;
          line-height: 30px;
        }

        .table {
          tbody {
            td {
              .radioBtn {
                .MuiRadio-root {
                  padding: 5px 9px;
                }
              }
            }
          }
        }
      }

      .partD {
        // margin-top: 2rem;

        .form {
          .input_div {
            label {
              margin-bottom: 0.5rem;
              font-size: 14px;
            }

            margin-bottom: 0.5rem;

            .upload_img {
              display: flex;
              position: relative;
              align-items: center;
              // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
              // border-radius: 15px !important;
              overflow: hidden;

              .form-control {
                border-radius: 6px 0px 0px 6px !important;
              }

              label {
                cursor: pointer;
                padding: 0.1rem 1rem !important;
                background: linear-gradient(90deg,
                    #1ebec8 -7.19%,
                    #86c65a 105.94%);
                border: 0;
                border-radius: 0px 6px 6px 0px !important;
                text-align: center;

                img {
                  max-width: 80%;
                }
              }
            }
          }
        }

        .personal_detail_label {
          display: block;
          position: relative;
          padding-left: 35px;
          /* margin-bottom: 20px; */
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          -webkit-user-select: none;
          user-select: none;
          color: #7f7e83;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: 4px;
            left: 5px;
            height: 18px;
            width: 18px;
            background-color: #fff;
            color: $text_color;
            //   border: 1px solid $Green;
            border-radius: 3px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }

            &:after {
              left: 5px;
              top: 1px;
              width: 7px;
              height: 12px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          input~.checkmark {
            background-color: #fff;
            border: 1px solid $text_color;
          }

          input:checked~.checkmark {
            background: $theme3;
            border: 1px solid $theme3;
          }

          input:checked~.checkmark:after {
            display: block;
          }
        }
      }
    }
  }
}

.content-section {
  overflow: auto;
  margin: 1rem 0;
}

.blink {
  min-width: auto !important;
  border-radius: 50% !important;
  width: 60px;
  height: 60px;
  font-size: 30px !important;
  color: #fff !important;
  background-color: #86c65a !important;
  animation: smooth-blink 1s ease-in-out infinite;
}

@keyframes smooth-blink {

  0%,
  100% {
    // opacity: 1;
    transform: scale(1);
  }

  50% {
    // opacity: 0.5;
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .HouseHoldMemberInformation {
    .card {
      width: 90%;

      .houseHoldForm {
        .table-responsive {
          &::-webkit-scrollbar {
            display: block;
            background-color: gainsboro;
            height: 10px;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: darkgray;
            border-radius: 3px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .HouseHoldMemberInformation {
    .card {
      width: 90%;

      h5 {
        font-size: 1.1rem;
      }

      .hed {
        .d-flex {
          flex-direction: column;
          gap: 10px;

          img {
            margin: auto;
          }

          h2 {
            font-size: 1.1rem;
          }
        }
      }

      .houseHoldForm {
        .partB {
          ul {
            padding-left: 1rem;

            li {
              font-size: 14px;

              .radioBtn {
                span {
                  &.MuiCheckbox-root {
                    padding: 0 2px 0 0px;
                  }

                  &.MuiFormControlLabel-label {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        .table-responsive {
          table {
            thead {
              th {
                font-size: 14px;
              }
            }

            tbody {
              td {
                font-size: 14px;

                .radioBtn {
                  margin-left: 0;

                  span {
                    &.MuiCheckbox-root {
                      padding: 0 2px 0 0px;
                    }

                    &.MuiFormControlLabel-label {
                      font-size: 14px;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }

          &::-webkit-scrollbar {
            display: block;
            background-color: gainsboro;
            height: 10px;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: darkgray;
            border-radius: 3px;
          }
        }
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .HouseHoldMemberInformation {
    padding: 0;

    .card {
      border-radius: 0px;
      border: 0;
      box-shadow: none;
      width: 100%;

      .image_with_dropdow {
        flex-direction: column;
        margin: 0;

        img {
          width: 100%;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 18px;
      }

      .hed {
        .d-flex {
          flex-direction: column;
          gap: 10px;

          img {
            margin: auto;
          }

          h2 {
            font-size: 1.1rem;
          }
        }
      }

      .houseHoldForm {
        .partB {
          ul {
            padding-left: 1rem;

            li {
              font-size: 14px;

              .radioBtn {
                span {
                  &.MuiCheckbox-root {
                    padding: 0 2px 0 0px;
                  }

                  &.MuiFormControlLabel-label {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        .table-responsive {
          margin-bottom: 1.5rem;

          table {
            thead {
              th {
                font-size: 14px;
              }
            }

            tbody {
              td {
                font-size: 14px;

                .radioBtn {
                  margin-left: 0;

                  span {
                    &.MuiCheckbox-root {
                      padding: 0 2px 0 0px;
                    }

                    &.MuiFormControlLabel-label {
                      font-size: 14px;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }

          &::-webkit-scrollbar {
            display: block;
            background-color: gainsboro;
            height: 10px;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: darkgray;
            border-radius: 3px;
          }
        }
      }

      p {
        font-size: 14px;
      }

      .form_guideline {
        .theme_btn {
          font-size: 14px !important;
        }
      }
    }
  }
}
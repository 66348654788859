@import "../../../../App.scss";

.HouseHoldMemberInformation_show {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .table {
    thead {
      th {
        vertical-align: middle;
        text-align: center;
        font-weight: 500;
      }
    }

    tbody {
      td {
        text-align: center;
        vertical-align: middle;
        font-weight: 400;

        .form-control {
          min-width: 150px;
        }

        .form-select {
          min-width: 150px;
        }

        button {
          min-width: fit-content;
          padding: 0.5rem !important;
        }

        .radioBtn {
          .MuiRadio-root {
            padding: 5px 9px;
          }
        }
      }
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    width: 90%;
    margin: auto;

    .hed {
      .info {
        margin-top: 1rem;

        p {
          margin: 0;
          font-size: 15px;
        }
      }
      .header_logo {
        // box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
        height: 120px;
        width: 120px;
      }
    }

    .houseHoldForm {
      .partA {
        ul {
          padding: 0;
          list-style: none;
          margin: 0;
          display: flex;
          gap: 0 2rem;
          margin-bottom: 0.5rem;

          &:first-child {
            margin-bottom: 1rem;
          }

          li {
            width: 100%;

            button {
              margin: 0;
              padding: 0.2rem 0.3rem !important;
              border: 0 !important;
              min-width: fit-content;

              .icon {
                font-size: 20px;
              }

              &.cancel_btn {
                background: gainsboro;
                color: inherit;
              }
            }
          }
        }
        .personal_detail_label {
          display: block;
          position: relative;
          padding-left: 35px;
          /* margin-bottom: 20px; */
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          -webkit-user-select: none;
          user-select: none;
          color: #7f7e83;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: -10px;
            left: 33%;
            height: 18px;
            width: 18px;
            background-color: #fff;
            color: $text_color;
            //   border: 1px solid $Green;
            border-radius: 3px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }

            &:after {
              left: 5px;
              top: 1px;
              width: 7px;
              height: 12px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          input ~ .checkmark {
            background-color: #fff;
            border: 1px solid $text_color;
          }

          input:checked ~ .checkmark {
            background: $theme3;
            border: 1px solid $theme3;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }
        }
      }

      .partB {
        ul {
          // padding: 0;
          list-style: decimal;
          line-height: 30px;
        }
      }

      .partC {
        // margin-top: 2rem;

        ul {
          // padding: 0;
          list-style: decimal;
          line-height: 30px;
        }

        .table {
          tbody {
            td {
              .radioBtn {
                .MuiRadio-root {
                  padding: 5px 9px;
                }
              }
            }
          }
        }
      }

      .partD {
        // margin-top: 2rem;

        .form {
          .input_div {
            label {
              margin-bottom: 0.5rem;
              font-size: 14px;
            }

            margin-bottom: 0.5rem;
            .upload_img {
              display: flex;
              position: relative;
              align-items: center;
              // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
              // border-radius: 15px !important;
              overflow: hidden;
              .form-control {
                border-radius: 6px 0px 0px 6px !important;
              }
              label {
                cursor: pointer;
                padding: 0.1rem 1rem !important;
                background: linear-gradient(
                  90deg,
                  #1ebec8 -7.19%,
                  #86c65a 105.94%
                );
                border: 0;
                border-radius: 0px 6px 6px 0px !important;
                text-align: center;
                img {
                  max-width: 80%;
                }
              }
            }
          }
        }
        .personal_detail_label {
          display: block;
          position: relative;
          padding-left: 35px;
          /* margin-bottom: 20px; */
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          -webkit-user-select: none;
          user-select: none;
          color: #7f7e83;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: 4px;
            left: 5px;
            height: 18px;
            width: 18px;
            background-color: #fff;
            color: $text_color;
            //   border: 1px solid $Green;
            border-radius: 3px;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }

            &:after {
              left: 5px;
              top: 1px;
              width: 7px;
              height: 12px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          input ~ .checkmark {
            background-color: #fff;
            border: 1px solid $text_color;
          }

          input:checked ~ .checkmark {
            background: $theme3;
            border: 1px solid $theme3;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }
        }
      }
    }
  }
}


@import "../../../../App.scss";

.reports-page {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
  }
}

.reports-page {
  .report-header {
    display: flex;
    align-items: center;
    gap: 30px;
    // margin: 10px 20px;
    padding: 0.5rem 3rem;

    h2 {
      font-size: 20px;
      color: #31b680;
      margin: 0;
    }
  }

  .tab_panel {
    padding-right: 1rem !important;
    overflow-y: scroll;
    height: 643px;
    padding: 0;
  }

  // .card-body {
  //   padding: 0 !important;
  //   margin: 0 20px 20px;
  // }

  .tab-section {
    padding: 0 !important;
    margin-bottom: 1rem;

    .tab_list .MuiTabs-flexContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
    }

    .tabs_link {
      border-radius: 6px;
      background: #f1f1f1;
      padding: 15px 11px;
      color: #31b680;
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      width: 95px;
      height: 80px;
      font-family: "Readex Pro", sans-serif;
      text-transform: capitalize;

      &:hover {
        background: var(
          --maincolor,
          linear-gradient(56deg, #18bad4 0%, #31b680 51.56%, #80c242 100%)
        );
        color: #fff;
        transition: 0.4s;
      }

      &.Mui-selected {
        background: var(
          --maincolor,
          linear-gradient(56deg, #18bad4 0%, #31b680 51.56%, #80c242 100%)
        );
        color: #fff;
      }
    }

    .MuiTabs-indicator {
      border: 0;
      display: none;
    }
  }

  .payment-section {
    padding: 0;
    overflow-y: scroll;
    height: 643px;
    padding-right: 1rem !important;

    .date-block {
      // display: flex;

      label {
        font-size: 15px;
        // margin: 0;
      }

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          position: relative;
          display: flex;
          align-items: center;

          svg {
            width: 1.5em;
            height: 1.5em;
            padding: 0;
            right: 0.5rem;
            fill: #4caf50;
          }

          input {
            border: 1px solid #3f425436;
            border-radius: 8.09942px;
            color: rgba(63, 66, 84, 0.9);
            font-size: 15px;
            font-weight: 500;
            font-family: inherit;
            width: 100%;
            &:focus-visible {
              outline: 0;
            }
          }
        }
      }
    }
  }
}

.tab_group ::-webkit-scrollbar {
  display: none;
}

.tab_group ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: none;
}

.tab_group ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  display: none;
}

/* Responsive Media Query */

/* 1024 Media */
@media (max-width: 1024px) {
  .reports-page .tab_group .css-heg063-MuiTabs-flexContainer {
    overflow-x: unset;
    width: 100%;
  }
}

@import "../../../../../../App.scss";

.create_meal_point {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }
    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;
      .upload_vendor_logo {
        display: flex;
        position: relative;
        align-items: center;
        // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
        // border-radius: 15px !important;
        overflow: hidden;
        .upload_box {
          border-radius: 8px 0px 0px 8px !important;
        }
        .form-control {
          border-radius: 6px 0px 0px 6px !important;
        }
        label {
          cursor: pointer;
          padding: 0.3rem 1rem !important;
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          border: 0;
          border-radius: 0px 6px 6px 0px !important;
          text-align: center;
          img {
            max-width: 80%;
          }
        }
      }
      // .Is_Free_MealOrder {
      //   .form-group {
      //     margin: 0;
      //     margin-bottom: 1rem;
      //     margin-top: auto;
      //     padding: 0.5rem 0rem;

      //     .personal_detail_label {
      //       position: relative;
      //       padding-left: 35px;
      //       cursor: pointer;
      //       color: $text_color2;
      //       font-weight: 400;
      //       font-size: 16px;
      //       -webkit-user-select: none;
      //       -moz-user-select: none;
      //       -ms-user-select: none;
      //       user-select: none;

      //       input {
      //         opacity: 0;
      //         cursor: pointer;
      //         height: 0;
      //         width: 0;
      //       }

      //       .checkmark {
      //         position: absolute;
      //         top: 3px;
      //         left: 0;
      //         height: 20px;
      //         width: 20px;
      //         background-color: #fff;
      //         border-radius: 6px;

      //         &:after {
      //           content: "";
      //           position: absolute;
      //           display: none;
      //         }

      //         &:after {
      //           left: 6px;
      //           top: 1px;
      //           width: 7px;
      //           height: 12px;
      //           border: solid white;
      //           border-width: 0 3px 3px 0;
      //           -webkit-transform: rotate(45deg);
      //           -ms-transform: rotate(45deg);
      //           transform: rotate(45deg);
      //         }
      //       }

      //       input ~ .checkmark {
      //         background-color: #fff;
      //         border: 1px solid $theme3;
      //       }

      //       input:checked ~ .checkmark {
      //         background: $theme_color;
      //       }

      //       input:checked ~ .checkmark:after {
      //         display: block;
      //       }
      //     }
      //   }
      // }
    }
  }
}

@import "../../../../../App.scss";

.month_end_page {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .line {
      border: 3px solid black;
    }
    .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
  }
  table {
    border: 1px solid #dee2e6;
    thead {
      tr {
        th {
          padding: 7px !important;
          font-weight: bold;
          font-size: 16px;
          background-color: transparent;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 7px !important;
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
}

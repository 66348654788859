@import "../../App.scss";

body,
html {
  font-family: "Readex Pro", sans-serif;
}

a.link {
  color: #0a58ca !important;
}

.main_wrapper {
  margin: 0 72px;
}

.card {
  border-radius: 10px !important;
  border: 0 !important;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
}

.card-body {
  padding: 1.5rem !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.loader {
  width: 100%;
  display: grid;
  height: 82vh;
  align-content: center;
  justify-items: center;
  background-color: #f9f9f9;
}

.error_text {
  font-size: 14px;
  color: $red;
}

.all_title {
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(86.33deg,
      #18bad4 1.51%,
      #31b680 52.02%,
      #80c242 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  line-height: inherit;
  border-bottom: 2px solid #31b680;
  display: inline-block;
  padding-bottom: 7px;
}

.theme_text {
  background: linear-gradient(86.33deg,
      #18bad4 1.51%,
      #31b680 52.02%,
      #80c242 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box__shadow {
  background: #ffffff;
  box-shadow: 4px 4px 26px rgba(0, 0, 0, 0.11);
}

.theme_btn {
  background: $theme_color;
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  text-decoration: none;
  padding: 0.7rem 1.5rem !important;
  line-height: initial !important;
}

.white_btn {
  background: #ffffff !important;
  color: #80c242 !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  text-decoration: none;
  padding: 0.7rem 1.5rem !important;
  line-height: initial !important;
}

.cancel_btn {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  padding: 0.7rem 1.5rem !important;
  line-height: initial !important;
  background-color: #e3e3e3 !important;
  color: rgba(63, 66, 84, 0.9) !important;
}

.delete_btn {
  background-color: $red !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  text-decoration: none;
  padding: 0.7rem 1.5rem !important;
  line-height: initial !important;
}

.orange_btn {
  font-size: 18px !important;
  font-weight: 400 !important;
  background: #f79155 !important;
  text-transform: none !important;
  color: #fff !important;
  padding: 0.7rem 1.5rem !important;
  border-radius: 5px !important;
  border: 0 !important;
  line-height: initial !important;
  text-decoration: none;

  .MuiTouchRipple-root {
    display: none;
  }
}

.form-group {
  position: relative;

  label {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 0.5rem;
    color: rgba(63, 66, 84, 0.9);
  }

  .form-control {
    border: 1px solid #3f425436;
    border-radius: 8px;
    color: rgba(63, 66, 84, 0.9);
    font-size: 15px;
    font-weight: 500;
    font-family: inherit;

    &::placeholder {
      color: $placeholder_color;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .form-select {
    border: 1px solid #3f425436;
    border-radius: 8.09942px;
    color: rgba(63, 66, 84, 0.9);
    font-size: 15px;
    font-weight: 500;
    font-family: inherit;

    &::placeholder {
      color: $placeholder_color;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .refrence {
    // bottom: -30px;
    color: #31b680;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 600;
    left: 10px;
    position: absolute;

    a {
      color: #a9a9a9;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }
  }
}

.form-group {
  margin-bottom: 1rem;

  .form-control {
    padding: 0.7rem 1rem;

    &::placeholder {
      color: $placeholder_color;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .form-select {
    padding: 0.7rem 1rem;

    &::placeholder {
      color: $placeholder_color;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.MuiFormControl-root {
  .MuiInputBase-formControl {
    padding: 0;

    .MuiSelect-select {
      padding: 0.7rem 1rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
}

.report-table {
  // padding-top: 30px;

  .table-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
    margin-bottom: 6px;

    &.cash-border {
      border-top: 1px dashed black;
      border-bottom: 1px dashed black;
      padding: 10px 0 4px;
      margin: 0 0 15px;
    }

    .title-width {
      width: 100%;
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .endreport-border {
    border-top: 1px dashed #000;
    padding-top: 15px;
    margin-top: 15px;
  }

  .table {
    margin-bottom: 0;

    thead {
      tr {
        th {
          vertical-align: middle;
          text-align: center;
          font-weight: 500;
          background-color: #f3f3f3;
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;
          text-align: center;
          font-weight: 500;

          .inner-table {
            tr {
              td {
                padding: 0.5rem 0.5rem;
                border-width: 1px 1px;

                &:last-child {
                  border-right: 0;
                }

                &:first-child {
                  border-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.f-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.f-16-400 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.f-18-600 {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}

.f-18-400 {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

.f-22-400 {
  font-size: 22px;
  font-weight: 400;
  line-height: 21px;
}

/* Responsive Media Query */

/* Max-width 1400PX */
@media (max-width: 1400px) {
  .main_wrapper {
    margin: 0 30px;
  }
}

/* Max-width 767PX */
@media (max-width: 767px) {
  .main_wrapper {
    margin: 0 20px;
  }
}

/* Max-width 481PX */
@media (max-width: 481px) {
  .theme_btn {
    padding: 10px !important;
    font-size: 16px !important;
  }

  .all_title {
    font-size: 20px;
  }

  .cancel_btn {
    padding: 10px !important;
    font-size: 16px !important;
  }
}
@import "../../../../../../App.scss";

.create_ExpenceCategory {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }
    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;
      .upload_vendor_logo {
        display: flex;
        position: relative;
        align-items: center;
        // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
        // border-radius: 15px !important;
        overflow: hidden;
          .upload_box {
            border-radius: 8px 0px 0px 8px !important;
          }
        .form-control {
          border-radius: 6px 0px 0px 6px !important;
        }
        label {
          cursor: pointer;
          padding: 0.3rem 1rem !important;
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          border: 0;
          border-radius: 0px 6px 6px 0px !important;
          text-align: center;
          img {
            max-width: 80%;
          }
        }
      }
    }
  }
}

@import "../../../../../../App.scss";

.student_navigation {
  margin: 0 0px;
  padding: 0px 0;
  background-color: $main_backgroundColor;
  // box-shadow: 0px 5px 10px rgba(145, 145, 145, 0.25);
  position: relative;

  .container-fluid {
    padding: 0;
  }

  .nav_bar {
    padding: 1rem 0;
    border-bottom: 1px solid rgb(147 145 145 / 34%);
    // box-shadow: 0px 8px 6px -6px rgba(145, 145, 145, 0.25);

    .container-fluid {
      padding: 0;
    }
  }

  .mobile_toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;

    .navbar-toggler {
      box-shadow: none;
      background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
      border: 0;
      border-radius: 50px;
      padding: 5px 15px;
    }

    .mobile-profile{
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .header_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_active {
      .header_link {
        text-decoration: none;
        margin: 0 18px;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: $text_color2;
        position: relative;
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        &:hover {
          color: $text_color;
        }

        &.active {
          color: $text_color;
        }
        .icon {
          display: block;
          margin-left: 0.5rem;
          color: #009688;
          font-size: 15px;
      }
      }
    }

    .user_d_box {
      display: flex;
      align-items: center;
      cursor: pointer;
      // padding: 0.6rem 1rem;
      // background-color: #fff;
      // border-radius: 10px;
      // box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

      img {
        border-radius: 50%;
        height: 54px;
        width: 54px;
      }

      .icon {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    
  }
}

.web_notification .MuiList-padding{
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  background: #FFF;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
  .menu-link{
    padding: 0;
  }
  .menu-link  a{
      color: #606060;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      background: #fff;
      padding-bottom: 18px;
      &:hover{
        background: transparent;
      }
    }
  
}

/* Responsive Media Query */

/* Max-width 1300PX */
@media (max-width: 1300px){
  .navigation .header_nav .header_active .header_link {
    margin: 0 10px;
    font-size: 16px;
}
}

/* Max-width 991PX */
@media (max-width: 991px) {
  .navigation .mobile_toggle{
    display: block;
    display: flex;
  }
  .main_logo{
    display: none;
  }
  .navigation .header_nav .user_d_box{
    display: none;
  }
  .mobile-profile .user_d_box{
    display: block;
     img{
      width: 50px;
      height: 50px;
     }
  }
  .navigation .header_nav .header_active .header_link{
    margin: 0;
  }
}
@import "../../App.scss";

.forgot_page {
  height: 100vh;
  .forgot_banner {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .forgot_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .head_title {
      background: $theme_color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      font-size: 42px;
      border-bottom: 3px solid $log_border_color;
      width: fit-content;
      margin-bottom: 5rem;
    }
    .forgot_detail {
      width: 50%;
      .label_with_icon {
        display: flex;
        align-items: center;
        color: $theme2;
        font-size: 20px;
        font-weight: 400;
      }
      .form-control {
        border: 1px solid $log_border_color;
      }
      .eye {
        cursor: pointer;
        position: absolute;
        right: 16px;
      }
      .forgot {
        color: rgba(96, 96, 96, 0.6);
        text-decoration: none;
      }
      .theme_btn {
        margin-top: 3rem;
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .forgot_page {
    .forgot_box {
      .forgot_detail {
        width: 90%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .forgot_page {
    .sidebanner {
      display: none;
    }

    .forgot_box {
      border: 1px dashed #67c587;
      border-radius: .375rem;
      padding: 1rem;

      .forgot_detail {
        width: 100%;

        .head_title {
          font-size: 30px;
          margin-bottom: 2.5rem;
        }

        .theme_btn {
          margin-top: 2.5rem;
        }
        .label_with_icon{
          font-size: 15px;
        }
        .forgot{
          font-size: 14px;
        }
        .form-control {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .forgot_page {
    .sidebanner {
      display: none;
    }

    .forgot_box {
      border: 1px dashed #67c587;
      border-radius: .375rem;
      padding: 1rem;

      .forgot_detail {
        width: 100%;

        .head_title {
          font-size: 30px;
          margin-bottom: 2.5rem;
        }

        .theme_btn {
          margin-top: 2.5rem;
        }
        .label_with_icon{
          font-size: 15px;
        }
        .forgot{
          font-size: 14px;
        }
        .form-control {
          font-size: 14px;
        }
      }
    }
  }
}
@import "../../../../../../App.scss";

.create_customer {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
    .tab_group{
      .MuiButtonBase-root{
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.58);
        line-height: inherit;
        display: inline-block;
        text-transform: capitalize;
      }
      .Mui-selected{
        background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .MuiTabs-indicator {
        background: linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%);
      }
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;
    }
  }
}

@import "../../../../../App.scss";

.mealpoint_listpage {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .productTitle {
    font-size: 30px;
    font-weight: 700;
  }

  .theme_btn {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }
  .filter_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      padding: 11px 20px !important;
    }
  }
}

@import "../../../../../../App.scss";

.create_product {
    padding: 1.5rem 0;
    background-color: #f9f9f9;

    .tab_group {
        .tabs_link {
            font-weight: 400;
            font-size: 22px;
            color: rgba(0, 0, 0, 0.58);
            text-transform: capitalize;

            &.Mui-selected {
                background: linear-gradient(86.33deg,
                        #18bad4 1.51%,
                        #31b680 52.02%,
                        #80c242 99.48%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .MuiTabs-indicator {
            background: $theme_color;
        }
    }

    .card {
        border-radius: 10px;
        border: 0;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

        .card-body {
            .tab_panel {
                .form-group {
                    .form-control {
                        border: 1px solid #3f425436;
                        border-radius: 8.09942px;
                        color: rgba(63, 66, 84, 0.9);
                        font-size: 15px;
                        font-weight: 500;
                        font-family: inherit;
                    }
                }
            }
        }
    }
}

/* Responsive Media Query */

/* Max-width 576PX */
@media (max-width: 576px){
    .create_product .tab_group .tabs_link{
        font-size: 18px;
        padding: 0 10px;
    }
}
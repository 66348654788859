@import "../../../App.scss";

.toggle_delete_data {
  .delete_data_box {
    border-radius: 12px;

    .popup_delete_detail_box {
      h6 {
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        text-transform: capitalize;
        color: #4e4e4e;
        padding: 1.5rem 1rem;
        border-bottom: 1px dashed gainsboro;
        margin-bottom: 0;
        text-align: center;
      }

      .popup_delete_detail {
        padding: 1.5rem;
        text-align: center;

        .delete_text {
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.58);
          margin-bottom: 2rem;

          span {
            color: $red;
          }
        }

        .popup_btn {
          border-radius: 5px;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 600;
          padding: 0.5rem 2rem;
          margin: 0 0.5rem;

          &.cancel {
            border: 1px solid $red;
            color: $red;
            background: #ffffff;
          }

          &.delete {
            border: 0;
            color: #ffffff;
            background-color: $red;
          }
        }
      }
    }
  }
}

.backColor {
  padding: 0.7rem 1.5rem !important;
  background-color: #e3e3e3 !important;
  color: rgba(63, 66, 84, 0.9) !important;
}

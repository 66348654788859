@import "../../../../../App.scss";

.login-section{
    margin: 50px 0;
    .login-box{
        border-left: 10px solid var(--map-color-breckfast, #67C587);
        background: rgba(127, 127, 127, 0.04);
        padding: 30px 50px;
        h6{
            font-size: 24px;
            margin-bottom: 12px;
        }
        a{
            color: rgba(0, 0, 0, 0.54);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;
            &:hover{
                background: var(--maincolor, linear-gradient(56deg, #18BAD4 0%, #31B680 51.56%, #80C242 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        p{
            color: rgba(0, 0, 0, 0.54);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
@import "../../../../App.scss";

.student_list {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .form-group {
      .form-control {
        border: 1px solid #3f425436;
        border-radius: 8.09942px;
        color: rgba(63, 66, 84, 0.9);
        font-size: 15px;
        font-weight: 500;
        font-family: inherit;
      }
    }
    .MuiPaper-root {
      padding: 0px;
      margin-bottom: 10px !important;
      border-radius: 20px 20px 0px 0px !important;
      &.MuiPaper-elevation {
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
          0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
      }
      .MuiAccordionSummary-root {
        &.Mui-expanded {
          border-bottom: 1px solid #dfe0e1;
          margin-bottom: 20px;
        }
      }
      .filter_by {
        margin-left: 15px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #87c65a;
      }
    }
    .upload_img {
      display: flex;
      position: relative;
      align-items: center;
      // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      // border-radius: 15px !important;
      overflow: hidden;
      .form-control {
        border-radius: 8px 0px 0px 8px !important;
      }
      label {
        cursor: pointer;
        padding: 0.32rem 1rem !important;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
        border: 0;
        border-radius: 0px 8px 8px 0px !important;
        text-align: center;
        img {
          max-width: 80%;
        }
      }
    }
    .form-neselect {
      .select__menu {
        // position: relative;
        z-index: 9;
      }
      .select__control {
        border-radius: 10px;
        border: 2px solid #d9dde7;
        font-size: 13px;
        box-shadow: none;
        .select__value-container {
          // .select__placeholder {
          //   display: none;
          // }
          // &.select__value-container--has-value {
          //   .select__multi-value {
          //     display: none;
          //   }
          // }
        }
      }
    }
      .mat_box_main {
      width: 100%;
      background: #ffffff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
      border-radius: 12px;
      .text_data {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: auto auto auto 0;
      }
      .num {
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 12px;
        background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
        color: #ffffff;
        width: max-content;
      }
      .btn_dlt {
        background: transparent;
        font-size: 20px;
        border: 0;
        .icon {
          color: $red;
        }
      }
    }
  }
  .product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }
}

.hide {
  border-radius: 5px;
  font-size: 19px;
  gap: 10px;
}

.create_product {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .tab_group {
    .tabs_link {
      font-weight: 400;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.58);
      text-transform: capitalize;

      &.Mui-selected {
        background: linear-gradient(
          86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .MuiTabs-indicator {
      background: $theme_color;
    }
  }
}

/* Responsive Media Query */

/* Max-width 576PX */
@media (max-width: 576px) {
  .create_product .tab_group .tabs_link {
    font-size: 18px;
    padding: 0 10px;
  }
}


@import "../../../../App.scss";

.parent_dashboard {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .hed_top {
    .form-group {
      .form-select {
        min-width: 200px;
      }
    }
  }

  .admin_main_box {
    .meal_main {
      padding: 0;
      border-radius: 0;

      .meal_sub_box {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 4px 4px 26px 0px rgba(0, 0, 0, 0.11);
        padding: 47px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 60px;
          height: 60px;
        }

        .m_name {
          color: $text_color2;
          margin: 10px 0;
          font-weight: 500;
          font-size: 17px;
        }

        .m_num {
          color: rgba(0, 0, 0, 0.58);
          font-size: 20px;
          font-weight: 700;
        }
      }
      .box_dash {
        width: 19.5%;
      }
    }

    .chart_box_1 {
      padding: 1.5rem;
      border-radius: 10px;
      height: 100%;

      .chart_hed {
        h4 {
          font-size: 22px;
          margin: 0;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }

        .sales_count {
          margin-top: 1rem;

          h6 {
            margin: 0;
          }

          span {
            font-size: 21px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .web_dashboard .admin_main_box .meal_main .meal_sub_box {
    padding: 15px;
  }
}
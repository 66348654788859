@import "../../../../../App.scss";

.childinformation-section {
  margin: 50px 0;
  .margin-bottom {
    margin-bottom: 20px;
  }
  .cancel_btn {
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    border-radius: 5px !important;
    padding: 0.7rem 1.5rem !important;
    line-height: initial !important;
    background-color: #e3e3e3 !important;
    color: rgba(63, 66, 84, 0.9) !important;
  }
  .radio-block {
    .MuiButtonBase-root {
      color: $theme3;
    }
  }
}

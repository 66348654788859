@import "../../../../../../App.scss";

.hide {
  border-radius: 5px;
  font-size: 19px;
  gap: 10px;
}

.create_product {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .tab_group {
    .tabs_link {
      font-weight: 400;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.58);
      text-transform: capitalize;

      &.Mui-selected {
        background: linear-gradient(
          86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .MuiTabs-indicator {
      background: $theme_color;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      .tab_panel {
        .form-group {
          .form-control {
            border: 1px solid #3f425436;
            border-radius: 8.09942px;
            color: rgba(63, 66, 84, 0.9);
            font-size: 15px;
            font-weight: 500;
            font-family: inherit;
          }
        }
      }
      .upload_img {
        display: flex;
        position: relative;
        align-items: center;
        // box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
        // border-radius: 15px !important;
        overflow: hidden;
        .form-control {
          border-radius: 8px 0px 0px 8px !important;
        }
        label {
          cursor: pointer;
          padding: 0.32rem 1rem !important;
          background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%);
          border: 0;
          border-radius: 0px 8px 8px 0px !important;
          text-align: center;
          img {
            max-width: 80%;
          }
        }
      }
      .form-neselect {
        .select__menu {
          // position: relative;
          z-index: 9;
        }
        .select__control {
          border-radius: 10px;
          border: 2px solid #d9dde7;
          font-size: 13px;
          box-shadow: none;
          .select__value-container {
            // .select__placeholder {
            //   display: none;
            // }
            // &.select__value-container--has-value {
            //   .select__multi-value {
            //     display: none;
            //   }
            // }
          }
        }
      }
      .input_add_box {
        .pr_box_gtup {
          .form-group1 {
            border: 1px solid gainsboro;
            // padding: 0.5rem;
            border-radius: 10px;
            overflow: hidden;

            .cancel_btn {
              margin: 0;
              border-radius: 0 !important;
              padding: 0 !important;
              background: gainsboro;
              border: 0 !important;
              color: inherit;

              .num {
                padding: 0.5rem 1rem;
              }

              .icon {
                font-size: 26px;
              }
            }
          }
        }

        .add_sec {
          border: 1px solid #4caf50;
          border-radius: 10px;
          overflow: hidden;

          input {
            border: 0;
          }

          .theme_btn {
            margin: 0;
            border-radius: 0 !important;
            padding: 0 !important;

            .icon {
              font-size: 26px;
            }
          }
        }
      }

      .atch_pdf {
        .theme_btn {
          gap: 10px;

          .icon {
            font-size: 25px;
          }
        }
      }
    }
  }
}

/* Responsive Media Query */

/* Max-width 576PX */
@media (max-width: 576px) {
  .create_product .tab_group .tabs_link {
    font-size: 18px;
    padding: 0 10px;
  }

}

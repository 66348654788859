@import "../../../../../App.scss";
.cashier_approve_report-page {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
    .he_fix {
      max-height: 160px;
      overflow-y: auto;
      border: 1px solid #3f425436;
      border-radius: 8px;
      padding: 0.7rem 1rem;
      .personal_detail_label {
        display: block;
        position: relative;
        padding-left: 35px;
        /* margin-bottom: 20px; */
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        -webkit-user-select: none;
        user-select: none;
        color: #7f7e83;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 3px;
          left: 5px;
          height: 18px;
          width: 18px;
          background-color: #fff;
          color: $text_color;
          //   border: 1px solid $Green;
          border-radius: 3px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          &:after {
            left: 5px;
            top: 1px;
            width: 7px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        input ~ .checkmark {
          background-color: #fff;
          border: 1px solid $text_color;
        }

        input:checked ~ .checkmark {
          background: $theme3;
          border: 1px solid $theme3;
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }
    }
  }
}
@import "../../../../../../App.scss";

.create_invoice {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .ProductCategories_hed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }
    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;
      label {
        font-size: 16px;
      }
      .MuiFormGroup-root {
        height: 100%;
        .customer_info {
          height: 100%;
          cursor: pointer;
          border: 1px solid #d6d7db;
          padding: 1rem;
          border-radius: 5px;
          color: #000000;
          min-height: 150px;
          .address {
            color: #787878;
            // margin-bottom: 50px;
          }
          .MuiButtonBase-root {
            padding: 0px !important;
          }
          .close_btn {
            cursor: pointer;
            font-size: 28px;
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
              position: absolute;
              left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              line-height: 20px;
              display: inline-block;
              color: #666;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 18px;
              height: 18px;
              border: 1px solid $theme_color;
              border-radius: 100%;
              background: #fff;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              content: "";
              width: 12px;
              height: 12px;
              background: $theme_color;
              position: absolute;
              top: 3px;
              left: 3px;
              border-radius: 100%;
              transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              transform: scale(1);
            }
            .MuiButtonBase-root {
              color: #80c242 !important;
            }
            .MuiFormControlLabel-root {
              vertical-align: baseline !important;
              margin: 0;
            }
          }
        }
      }
      .items_div {
        border: 1px solid #d6d7db;
        padding: 1rem 1rem;

        border-radius: 8px;
        .w-60 {
          width: 60%;
          margin-bottom: 20px;
        }
        .w-40 {
          width: 40%;
          margin-bottom: 20px;
          .remove_btn {
            cursor: pointer;
            border: 1px solid #d6d7db;
            border-radius: 5px;
            padding: 10px 10px;
            font-size: 20px;
            display: flex;
            svg {
              color: $red;
            }
          }
        }
        .form-group {
          margin-bottom: 0;
        }
        .main_hours {
          .hours_item {
            width: 50%;
            margin-bottom: 20px;
          }
          .sub_hours {
            width: 35%;
            margin-bottom: 20px;
          }
          .sub_rate {
            width: 35%;
            margin-bottom: 20px;
          }
          .sub_desc {
            .remove_btn {
              cursor: pointer;
              border: 1px solid #d6d7db;
              border-radius: 5px;
              padding: 10px 10px;
              font-size: 20px;
              display: flex;
              svg {
                color: $red;
              }
            }
          }
        }
        .main_quantity {
          .quantity_item {
            width: 40%;
            margin-bottom: 20px;
          }
          .sub_quantity_name {
            width: 20%;
            margin-bottom: 20px;
          }
          .sub_quantity_price {
            width: 20%;
            margin-bottom: 20px;
          }
          .quantity_tax {
            width: 30%;
            margin-bottom: 20px;
          }
          .sub_quantity_desc {
            .remove_btn {
              cursor: pointer;
              border: 1px solid #d6d7db;
              border-radius: 5px;
              padding: 10px 10px;
              font-size: 20px;
              display: flex;
              svg {
                color: $red;
              }
            }
          }
        }
      }
      .add_item {
        margin: 1rem 0rem;
        color: #2cc56f;
        background: transparent !important;
        text-transform: capitalize !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        border-radius: 5px !important;
        text-decoration: none;
        line-height: initial !important;
        text-align: left !important;
      }
      .terms_ref {
        display: flex;
        justify-content: flex-start;
        .add_terms_condition_number {
          color: #2cc56f;
          background: transparent !important;
          text-transform: capitalize !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          border-radius: 5px !important;
          text-decoration: none;
          line-height: initial !important;
          text-align: left !important;
        }
      }
      .MuiPaper-root {
        padding: 0px;
        border-radius: 20px 20px 0px 0px !important;
        &.MuiPaper-elevation {
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
            0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
        }
        .MuiAccordionDetails-root {
          padding: 0px !important;
        }
        .MuiAccordionSummary-root {
          border-bottom: 1px solid #d6d7db;
          padding: 0px 0px !important;
          &.Mui-expanded {
            margin-bottom: 15px;
          }
        }
        .attach_img_file {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: dashed 1px rgba(186, 186, 186, 0.1490196078);
          border-width: 3px;
          border-radius: 5px;
          padding: 7px, 13px, 7px, 13px;
          width: 168px;
          height: 44px;
          .upload-text {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.36px;
          }
        }
        .file_count {
          border-radius: 20px;
          border: 1px solid #2cc56f;
          padding: 2px 7px;
          background-color: #2cc56f;
          color: white;
        }
        .file_box_main {
          width: 100%;
          background: #ffffff;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
          border-radius: 5px;
          .text_data {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin: auto auto auto 0;
          }
          .num {
            border-radius: 6px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            width: max-content;
          }
          .btn_dlt {
            background: transparent;
            font-size: 20px;
            border: 0;
            .icon {
              color: $red;
            }
          }
        }
      }
    }
  }
  label {
    font-size: 16px;
  }
  .card-container {
    padding: 1rem 1.5rem;
  }
  .total_discount {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .sub_total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #787878;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
      button {
        border: 0;
        background: transparent;
        color: #2cc56f;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .cmp_image {
    img {
      border-radius: 5px;
    }
  }
  .cross_icon {
    cursor: pointer;
    font-size: 28px;
    position: relative;
    bottom: 15px;
    left: 5px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .card-body {
      padding: 2rem 2.5rem;
      label {
        font-size: 16px;
      }
      .MuiFormGroup-root {
        height: 100%;
        .customer_info {
          height: 100%;
          cursor: pointer;
          border: 1px solid #d6d7db;
          padding: 1rem;
          border-radius: 5px;
          color: #000000;
          min-height: 150px;
          .address {
            color: #787878;
            // margin-bottom: 50px;
          }
          .MuiButtonBase-root {
            padding: 0px !important;
          }
          .close_btn {
            cursor: pointer;
            font-size: 28px;
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
              position: absolute;
              left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              line-height: 20px;
              display: inline-block;
              color: #666;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 18px;
              height: 18px;
              border: 1px solid #31b680;
              border-radius: 100%;
              background: #fff;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              content: "";
              width: 12px;
              height: 12px;
              background: #31b680;
              position: absolute;
              top: 3px;
              left: 3px;
              border-radius: 100%;
              transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              transform: scale(1);
            }
            .MuiButtonBase-root {
              color: #31b680 !important;
            }
            .MuiFormControlLabel-root {
              vertical-align: baseline !important;
              margin: 0;
            }
          }
        }
      }
      .items_div {
        border: 1px solid #d6d7db;
        padding: 1rem 1rem;
        border-radius: 8px;
        div {
          display: block !important;
        }
        .w-60 {
          width: 100% !important;
          margin-bottom: 20px;
        }
        .w-40 {
          width: 100% !important;
          margin-bottom: 20px;
          display: flex !important;
          .remove_btn {
            cursor: pointer;
            border: 1px solid #d6d7db;
            border-radius: 5px;
            padding: 7px 7px !important;
            font-size: 20px;
            svg {
              color: $red;
            }
          }
        }
        .form-group {
          margin-bottom: 0;
        }
        .main_hours {
          .hours_item {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .sub_hours {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .sub_rate {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .sub_desc {
            display: flex !important;
            .remove_btn {
              cursor: pointer;
              border: 1px solid #d6d7db;
              border-radius: 5px;
              padding: 7px 7px !important;
              font-size: 20px;
              svg {
                color: $red;
              }
            }
          }
        }
        .main_quantity {
          .quantity_item {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .sub_quantity_name {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .sub_quantity_price {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .quantity_tax {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .sub_quantity_desc {
            display: flex !important;
            .remove_btn {
              cursor: pointer;
              border: 1px solid #d6d7db;
              border-radius: 5px;
              padding: 7px 7px !important;
              font-size: 20px;
              svg {
                color: $red;
              }
            }
          }
        }
      }
      .add_item {
        margin: 1rem 0rem;
        color: #2cc56f;
        background: transparent !important;
        text-transform: capitalize !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        border-radius: 5px !important;
        text-decoration: none;
        line-height: initial !important;
        text-align: left !important;
      }
      .terms_ref {
        display: block !important;
        .add_terms_condition_number {
          color: #2cc56f;
          background: transparent !important;
          text-transform: capitalize !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          border-radius: 5px !important;
          text-decoration: none;
          line-height: initial !important;
          text-align: left !important;
        }
      }
      .MuiPaper-root {
        padding: 0px;
        border-radius: 20px 20px 0px 0px !important;
        &.MuiPaper-elevation {
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
            0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
        }
        .MuiAccordionDetails-root {
          padding: 0px !important;
        }
        .MuiAccordionSummary-root {
          border-bottom: 1px solid #d6d7db;
          padding: 0px 0px !important;
          &.Mui-expanded {
            margin-bottom: 15px;
          }
        }
        .attach_img_file {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: dashed 1px rgba(186, 186, 186, 0.1490196078);
          border-width: 3px;
          border-radius: 5px;
          padding: 7px, 13px, 7px, 13px;
          width: 168px;
          height: 44px;
          .upload-text {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.36px;
          }
        }
        .file_count {
          border-radius: 20px;
          border: 1px solid #2cc56f;
          padding: 2px 7px;
          background-color: #2cc56f;
          color: white;
        }
        .file_box_main {
          width: 100%;
          background: #ffffff;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0px 7px 8px rgb(202 202 202 / 25%);
          border-radius: 5px;
          .text_data {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin: auto auto auto 0;
          }
          .num {
            border-radius: 6px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            width: max-content;
          }
          .btn_dlt {
            background: transparent;
            font-size: 20px;
            border: 0;
            .icon {
              color: $red;
            }
          }
        }
      }
    }
  }
  label {
    font-size: 16px;
  }
  .card-container {
    padding: 1rem 1.5rem;
  }
  .total_discount {
    .sub_total {
      display: flex;
      justify-content: space-between;
      p {
        color: #787878;
        width: 106.4px;
        height: 21px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }
      button {
        border: 0;
        background: transparent;
        color: #2cc56f;
        width: 56.93px;
        height: 24px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
      }
    }
  }
}

.custom_page_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  .iconButton {
    &.left {
      background: #191919;
      border: 1px solid #191919;
      font-size: medium;
      .icon {
        color: #fff;
      }
    }

    &.right {
      background: #191919;
      border: 1px solid #191919;
      font-size: medium;
      .icon {
        color: #fff;
      }
    }

    &.disabled {
      pointer-events: none;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      .icon {
        color: #191919;
      }
    }
  }
}

@import "../../../../../App.scss";

.communicate_list {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

    .MuiPaper-root {
      padding: 0px;
      margin-bottom: 10px !important;
      border-radius: 20px 20px 0px 0px !important;
      &.MuiPaper-elevation {
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
          0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
      }
      .MuiAccordionSummary-root {
        &.Mui-expanded {
          border-bottom: 1px solid #dfe0e1;
          margin-bottom: 20px;
        }
      }
      .filter_by {
        margin-left: 15px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #87c65a;
      }
    }
  }
  .product_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }
}

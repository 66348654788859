@import "../../../../App.scss";

.admin_newdashboard {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;
  .main_wrapper {
    .color-green {
      color: #2cc56f;
    }
    .color-grey {
      color: #979797;
    }
    .top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .swith-area {
        // display: flex;
        // align-items: center;
        .switch-text {
          color: #2cc56f;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
        }
        .color-grey {
          color: #babec6;
        }
      }
      .sites-area {
        display: flex;
        align-items: center;
        gap: 10px;
        .form-group {
          margin-bottom: 0;
          .form-select {
            padding: 10px 50px 10px 10px;
            border-radius: 4px;
            border: 1px solid #d5d5d5;
          }
        }
      }
    }
    .wht-box {
      border-radius: 14px;
      border: 1px solid #f2f2f2;
      background: #fff;
      box-shadow: 0px 6px 9px -5px rgba(186, 186, 186, 0.15);
      padding: 30px 20px;
      height: 100%;
      .border-right {
        border-right: 1px solid #e2e2e2;
      }
    }
  }
}

/* responsive media qurey */

@import "../../../../../../../App.scss";

.product_manage_popup {
  .delete_data_box {
    border-radius: 12px;
    .upload_product_logo {
      display: flex;
      position: relative;
      // align-items: center;
      //   border-radius: 15px !important;
      overflow: hidden;

      .upload_box {
        border-radius: 8px 0px 0px 8px !important;
      }

      label {
        cursor: pointer;
        background: $theme_color;
        border: 0;
        border-radius: 0px 8px 8px 0px !important;
        text-align: center;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 22px !important;

        img {
          max-width: 80%;
        }
      }
    }
  }
}

@import "../../App.scss";

.help_page {
  // padding-top: 20px;
  // padding-bottom: 20px;
  // min-height: 86vh;
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .help_t_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .help_title {
      //  font-size: 50px;
      // background: linear-gradient(90deg, $leniar_color1 -7.19%, $Green 40%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // margin-bottom: 24px;
      // filter: drop-shadow(2px -2px 0px $text_color);
      font-size: 25px;
      font-weight: 700;
      line-height: 38px;
    }
  }

  .help_content {
    .help_content_card_show {
      // padding: 1rem;
      background-color: white;
      border-radius: 10px;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);

      .MuiTabs-fixed {
        background: #fff;
        padding: 1rem;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed gainsboro;

        .MuiTabs-flexContainer {
          .tabs_btn {
            text-transform: capitalize;
            font-size: 17px;
            padding: 0.5rem 1.5rem;
            font-family: inherit;
            color: #2c2e35;
            border-radius: 5px;
            z-index: 9;
            min-height: auto;
            height: 40px;

            .MuiTouchRipple-root {
              display: none;
            }

            &.Mui-selected {
              background: transparent;
              // color: #fff;
            }
          }
        }

        .MuiTabs-indicator {
          background: linear-gradient(
            90deg,
            #1ebec86b -7.19%,
            #86c65a78 105.94%
          );
          bottom: inherit;
          height: 40px;
          border-radius: 5px;
        }
      }

      .tab_pannel {
        padding: 1rem;

        .no_data {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }

        .back_btn {
          background-color: transparent;
          border: 1px solid gainsboro;
          // color: $leniar_color1;
          border-radius: 50px;
          margin-left: 20px;
          padding: 14 20px !important;
          text-transform: capitalize;
        }

        .myTicket {
          .title {
            -webkit-text-fill-color: transparent;
            background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
            -webkit-background-clip: text;
            font-size: 25px;
            font-weight: 700;
            width: max-content;
          }

          .content {
            margin-top: 2rem;

            ul {
              padding: 0;
              margin: 0;

              li {
                list-style: none;
                display: flex;
                padding: 0.5rem 0;

                .left {
                  font-size: 16px;
                  font-weight: 600;
                  color: #2c2e35;
                  width: 180px;
                }

                .right {
                  border-bottom: 1px dashed gainsboro;
                  font-weight: 400;
                  font-size: 15px;
                  width: 100%;

                  &.no_br {
                    border: 0;
                  }

                  .text_box {
                    padding: 0.5rem;
                    // border-left: 2px solid gainsboro;
                    position: relative;

                    &.FigUs {
                      &::after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 3px;
                        background: linear-gradient(
                          90deg,
                          rgba(30, 190, 200, 0.4196078431) -7.19%,
                          rgba(134, 198, 90, 0.4705882353) 105.94%
                        );
                        left: 0;
                        top: 0;
                      }
                    }

                    &.FigAd {
                      &::after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 3px;
                        background: gainsboro;
                        left: 0;
                        top: 0;
                      }
                    }
                  }

                  p {
                    font-weight: 400;
                    font-size: 14px;
                    white-space: pre-line;
                    letter-spacing: 0.5px;

                    span {
                      font-weight: 500;
                      font-size: 17px;
                    }
                  }
                }
              }
            }

            .reply_div {
              label {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0.5rem;
              }

              .form-control {
                background-color: #fff !important;
                border: 1px solid gainsboro !important;

                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }

        .viewClosedTicket {
          .title {
            -webkit-text-fill-color: transparent;
            background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
            -webkit-background-clip: text;
            font-size: 25px;
            font-weight: 700;
            width: max-content;
          }

          .content {
            .data_table {
              margin-top: 1.5rem;

              thead {
                tr {
                  background: linear-gradient(
                    90deg,
                    #1ebec826 -7.19%,
                    #86c65a30 105.94%
                  );

                  th {
                    font-size: 16px;
                    font-weight: 600;
                    background-color: transparent;
                    border: 0;
                    padding: 16px;
                  }
                }
              }

              tbody {
                tr {
                  &:nth-of-type(even) {
                    background: linear-gradient(
                      90deg,
                      #1ebec826 -7.19%,
                      #86c65a30 105.94%
                    );
                  }

                  td {
                    font-weight: 500;
                    font-size: 15px;
                    border: 0;
                    padding: 16px;

                    .status {
                      &.red {
                        color: red;
                      }

                      &.green {
                        color: green;
                      }

                      &.black {
                        color: #2c2e35;
                      }

                      &.blue {
                        color: #666666;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .CreateTicket {
          .title {
            -webkit-text-fill-color: transparent;
            background: linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 40%);
            -webkit-background-clip: text;
            font-size: 25px;
            font-weight: 700;
            // width: max-content;
          }

          .content {
            margin-top: 2rem;

            ul {
              padding: 0;
              margin: 0;

              li {
                list-style: none;
                padding: 0.5rem 0;
                font-size: 16px;
                font-weight: 600;
                color: #2c2e35;
                border-bottom: 1px dashed gainsboro;

                &:last-child {
                  border-bottom: 0;
                }
              }
            }

            .form-group {
              margin-bottom: 20px;

              label {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0.5rem;
              }

              .form-control {
                background-color: #fff !important;
                border: 1px solid gainsboro !important;

                &:focus {
                  box-shadow: none;
                }
              }

              .form-select {
                background-color: #fff !important;
                border: 1px solid gainsboro !important;

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .MuiFormControlLabel-label {
              font-size: 15px;
              font-weight: 500;
              font-family: inherit;
            }

            .theme_btn {
              background: linear-gradient(
                90deg,
                #1ebec8 -7.19%,
                #86c65a 105.94%
              );
              color: #fff;
              padding: 14px 50px;
            }
          }
        }

        .upload_img {
          display: flex;
          position: relative;
          // align-items: center;
          //   border-radius: 15px !important;
          overflow: hidden;

          .upload_box {
            border-radius: 8px 0px 0px 8px !important;
          }

          label {
            cursor: pointer;
            background: goldenrod;
            border: 0;
            border-radius: 0px 15px 15px 0px !important;
            text-align: center;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 22px !important;

            img {
              max-width: 80%;
            }
          }
        }

        .list_my_ticket {
          .head_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .ticket_title {
              // background: linear-gradient(
              //   90deg,
              //   $leniar_color1 -7.19%,
              //   $Green 40%
              // );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 20px;
              font-weight: 600;
            }

            .search_input {
              display: flex;
              position: relative;
              align-items: center;

              input {
                background: #ffffff !important;
                box-shadow: 0px 0px 7px gainsboro;
                border-radius: 50px !important;
                padding: 11px 16px !important;
                border: 0;
                // width: 700px;
              }

              .icon {
                position: absolute;
                right: 4px;
                margin: auto 0;
                font-size: 40px;
                background-color: gainsboro;
                color: darkgray;
                border-radius: 50%;
                padding: 5px;
                // background: linear-gradient(90deg, #1ebec882 -7.19%, #86c65a82 40%);
              }

              img {
                position: absolute;
                top: 37%;
                right: 16px;
              }
            }
          }

          .table-box {
            box-shadow: none;

            table {
              thead {
                tr {
                  background: linear-gradient(
                    90deg,
                    #1ebec826 -7.19%,
                    #86c65a30 105.94%
                  );

                  th {
                    font-weight: 600;
                    font-size: 16px;
                    background-color: transparent;
                    border: 0;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    font-weight: 500;
                    font-size: 15px;
                    border: 0;
                    &:hover {
                      color: blue;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          .no_found {
            padding: 3rem;
            font-size: 20px;
            font-weight: 600;
          }

          .page_nation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid gainsboro;
            margin-top: 10px;

            .rowsPerPageOptions {
              .p_18_text {
                color: #2c2e35;
                font-size: 14px;
              }

              .pagination_select {
                padding: 5px 16px;
                margin: 0 8px;
                color: #666666;
                background: #fff;
                border: 1px solid gainsboro;
                border-radius: 8px;
                outline: 0;
              }

              .pagination-container {
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../../../../App.scss";

.createUserManage {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .checkbox_meal {
      .form-group {
        margin: 0;
        margin-bottom: 1rem;
        margin-top: auto;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        border: 1px solid rgba(63, 66, 84, 0.2117647059);

        .personal_detail_label {
          margin: 0;

          .checkmark {
            top: 2px;
          }
        }
      }
    }
    .personal_detail_label {
      // display: block;
      position: relative;
      padding-left: 35px;
      // margin-bottom: 20px;
      cursor: pointer;
      color: $text_color2;
      font-weight: 400;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        // position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        //   border: 1px solid $Green;
        border-radius: 6px;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }

        &:after {
          left: 6px;
          top: 1px;
          width: 7px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      input ~ .checkmark {
        background-color: #fff;
        border: 1px solid $theme3;
      }

      input:checked ~ .checkmark {
        background: $theme_color;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }
}

.chart_for_all {
  // border: 1px solid #E7E7E7;
  // padding: 1rem;

  .highcharts-credits {
    display: none;
  }
  .highcharts-root {
    .highcharts-data-labels {
      .highcharts-data-label {
        .highcharts-text-outline {
          stroke: none;
        }
      }
    }
  }
  .highcharts-legend-item {
    .highcharts-point {
      rx: 0;
      ry: 0;
    }
  }
}

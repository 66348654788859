@import "../../../../App.scss";

.session-graph {
  padding: 1.5rem 0;
  background-color: $main_backgroundColor;

  .MuiTabs-indicator {
    background-color: #88C75B;
  }

  .MuiTabs-flexContainer {
    .tabs_btn {
      text-transform: capitalize;
      color: #000;
      font-size: 20px;
      font-weight: 600;

      &.Mui-selected {
        color: #88C75B;
      }
    }
  }

  .main_wrapper {
    .top-action-control {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .select-action {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    .color-green {
      color: #2cc56f;
    }

    .color-grey {
      color: #979797;
    }

    .top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .swith-area {

        // display: flex;
        // align-items: center;
        .switch-text {
          color: #2cc56f;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
        }

        .color-grey {
          color: #babec6;
        }
      }

      .sites-area {
        display: flex;
        align-items: center;
        gap: 10px;

        .form-group {
          margin-bottom: 0;
          // .form-select {
          // padding: 10px 50px 10px 10px;
          // border-radius: 4px;
          // border: 1px solid #d5d5d5;
          // }
        }
      }
    }

    .wht-box {
      border-radius: 14px;
      border: 1px solid #f2f2f2;
      background: #fff;
      box-shadow: 0px 6px 9px -5px rgba(186, 186, 186, 0.15);
      padding: 30px 20px;
      height: 100%;

      p {
        font-size: 16px;
        line-height: 26px;
        text-align: center;
      }

      .cashier_report-page {
        padding: 0;
        background-color: transparent;

        .main_wrapper {
          margin: 0;

          .card {
            border-radius: 0;
            border: 0;
            box-shadow: none;

            .card-body {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}

.surveyAnalytics {
  margin-top: 1rem;

  .MealBenefit {
    .bubbolchart {
      position: relative;
    }

    hr {
      margin: 10px 0;
      border: 0;
      height: 1px;
      background-color: gainsboro;
    }

    .legends {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .PaymentCycle {
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding: 0;
      margin: 41px 0 0 0;

      li {
        // display: flex;
        // align-items: center;

        .progrssBar {
          .bar {
            display: flex;
            align-items: center;
            gap: 1rem;

            .MuiLinearProgress-colorPrimary {
              background-color: #F5F6FA !important;
              height: 6px !important;
            }
          }
        }
      }
    }
  }
}

/* responsive media qurey */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .session-graph {
    .main_wrapper {
      .top-action-control {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        .MuiTabs-root {
          .MuiTabs-scroller {
            .MuiButtonBase-root {
              padding: 0.5rem !important;
              font-size: 16px !important;
              font-weight: 500;
              letter-spacing: 0;
            }
          }
        }

        .select-action {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .sites-area {
            width: 100%;

            .form-group {
              width: 100%;
            }
          }
        }
      }

      .tab_pannel {
        padding: 0;
        .wht-box{
          padding: 15px 15px;
        }
      }
    }
  }
}
@import "../../../../App.scss";

.meals_information {
  padding: 1.5rem 0;
  background-color: #f9f9f9;
  .hed_top {
    .form-group {
      .form-select {
        min-width: 200px;
      }
    }
  }
  .product_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .productTitle {
      font-size: 30px;
      font-weight: 700;
    }

    .theme_btn {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .white-box{
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-top: 2rem;

    .title-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@import "../../../../App.scss";

.payment_success {
    padding: 0;
    background-color: $main_backgroundColor;
    top: -1px;
    position: relative;

    .main_wrapper {
        margin: 0 20%;

        .payment_inner {
            padding: 3rem 0;

            .success_message_box {
                background-color: #4CAF50;
                color: #fff;
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 1rem;
                border-radius: 10px;
                .icon {
                    font-size: 45px;
                }

                h1 {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                }

                p {
                    margin: 0;
                    font-size: 19px;
                }
            }

            .paymentAndUserInfo {
                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;

                    li {
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 20px;
                        padding: 1rem;
                        border-radius: 10px;
                        margin-bottom: 1rem;
                        border: 1px dashed gray;
                        color: gray;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        span {
                            color: #4e4e4e;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

}
@import "../../../../../../App.scss";

.btn_control {
  display: flex;
  justify-content: center;
  gap: 1rem;
  background-color: #f9f9f9;
}
.invoice_pdf {
  // padding: 1.5rem 2rem;
  background-color: $main_backgroundColor;
  display: flex;
  justify-content: center;
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    padding: 2.5rem;
    width: 900px;
    .cmp_detail {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      .cmp_img {
        img {
          border-radius: 10px;
        }
      }
    }
    .vendor_details {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      .name_address_amount {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #f6f8fc;
        border-radius: 10px;
        padding: 10px 35px 10px 12px;
        h4 {
          margin: 10px 0px;
        }
      }
    }
    .invoice_meta {
      border: 1px solid #ebeff6;
      border-radius: 10px;
      padding: 7px 15px 15px 15px;
      .meta_item {
        display: flex;
        flex-direction: column;
        background: #f6f8fc;
        margin-top: 8px;
        padding: 12px;
        border-radius: 10px;
      }
    }

    .item_title {
      margin-top: 20px;
    }

    .item_table {
      width: 100%;
      border-collapse: collapse;

      th:nth-child(2) {
        text-align: right;
      }
      th,
      td {
        padding: 12px;
        border: 1px solid #ddd;
        text-align: left;
        font-size: 16px;
      }
      td:nth-child(2) {
        text-align: right;
      }

      th {
        background-color: #f0f0f0;
        font-weight: bold;
      }
    }

    .summary {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .notes {
        flex: 1;
        padding-right: 20px;

        p {
          font-size: 14px;
          color: #555;
          margin: 0 0 10px;

          strong {
            font-weight: bold;
            color: #333;
          }
        }
      }

      .totals {
        width: 40%;
        .invoice-total {
          background-color: #f6f8fc;
          padding: 10px;
          border-radius: 5px;
        }

        div {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          margin-bottom: 10px;
          padding: 0 10px;
          span {
            color: #787878;
          }

          &:last-child span:last-child {
            font-size: 18px;
            font-weight: bold;
            color: #2cc56f;
          }
        }
      }
    }
    .terms {
      p {
        font-size: 14px;
        color: #333;
      }
    }

    .attachments {
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-top: 1px dashed gainsboro;
      border-bottom: 1px dashed gainsboro;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    padding: 2.5rem;
    width: 900px;
    .cmp_detail {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      .cmp_address {
        .cmp_img {
          img {
            border-radius: 10px;
          }
        }
        h5,
        p {
          font-size: 16px;
        }
      }
    }
    .vendor_details {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      .name_address_amount {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #f6f8fc;
        border-radius: 10px;
        padding: 10px 35px 10px 12px;
        p,
        span {
          font-size: 14px;
        }
        h4 {
          font-size: 18px;
          margin: 10px 0px;
        }
      }
    }
    .invoice_meta {
      border: 1px solid #ebeff6;
      border-radius: 10px;
      padding: 7px 15px 15px 15px;
      .meta_item {
        display: flex;
        flex-direction: column;
        background: #f6f8fc;
        margin-top: 8px;
        padding: 12px;
        border-radius: 10px;
        strong {
          font-size: 16px;
        }
        span {
          font-size: 14px;
        }
      }
    }

    .item_title {
      font-size: 16px;
      margin-top: 20px;
    }

    .item_table {
      width: 100%;
      border-collapse: collapse;

      th:nth-child(2) {
        text-align: right;
      }

      th,
      td {
        padding: 12px;
        border: 1px solid #ddd;
        text-align: left;
        font-size: 16px;
      }
      td:nth-child(2) {
        text-align: right;
      }

      th {
        background-color: #f0f0f0;
        font-weight: bold;
      }
    }

    .summary {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .notes {
        flex: 1;
        padding-right: 20px;
        strong {
          font-size: 14px;
        }

        p {
          font-size: 14px;
          color: #555;
          margin: 0 0 10px;

          strong {
            font-weight: bold;
            color: #333;
          }
        }
      }

      .totals {
        width: 40%;
        .invoice-total {
          background-color: #f6f8fc;
          padding: 10px;
          border-radius: 5px;
        }

        div {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          margin-bottom: 10px;
          padding: 0 10px;
          flex-wrap: wrap;
          span {
            color: #787878;
            font-size: 14px;
          }
          h5 {
            font-size: 16px;
          }

          &:last-child span:last-child {
            font-size: 16px !important;
            font-weight: bold;
            color: #2cc56f;
          }
        }
      }
    }
    .terms {
      p {
        font-size: 14px;
        color: #333;
      }
      strong {
        font-size: 14px;
      }
    }

    .attach_title {
      font-size: 18px;
    }
    .attachments {
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-top: 1px dashed gainsboro;
      border-bottom: 1px dashed gainsboro;
      span {
        font-size: 14px;
      }
      img {
        font-size: 14px !important;
      }
    }
  }
}

@import "../../../../App.scss";

.payment-history-page{
  padding: 1.5rem 0;
  background-color: #f9f9f9;
  .filter-img{
    img{
      width: 18px;
      height: 18px;
    }
  }
  .white-box{
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-top: 50px;
  }
}



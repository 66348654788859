.blockreport_box {
    width: max-content;
    // position: absolute !important;
    // z-index: 9 !important;

    // .MuiModal-backdrop {
    //     width: max-content;
    //     position: absolute !important;
    //     z-index: 0 !important;
    // }

    .MuiPaper-rounded {
        min-width: 150px;
        max-width: max-content;
        box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 13%);
        border-radius: 10px;

        ul {
            padding: 0.5rem;

            li {
                padding: 0.8rem 1rem;
                font-size: 16px;
                font-weight: 500;
                color: #5E6278;
                transition: 500ms;
                background-color: transparent;
                border-bottom: 1px dashed rgb(199 199 199 / 32%);

                .icon {
                    margin-right: 1rem;
                    color: #009688;
                    font-size: 1.5rem !important;
                    width: 2.5rem;
                    line-height: initial
                }

                .bac {
                    display: flex;
                    flex-direction: column;
                    line-height: initial;

                    span {
                        color: #A1A5B7;
                        line-height: initial;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                &:hover {
                    background-color: transparent;
                    color: #009688;
                    transform: scale(1.05);

                    .icon {
                        color: #009688;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.megamenu {
    width: max-content;
    // position: absolute !important;
    // z-index: 9 !important;

    // .MuiModal-backdrop {
    //     width: max-content;
    //     position: absolute !important;
    //     z-index: 0 !important;
    // }
    &.wideSubMenu {
        .MuiPaper-rounded {
            min-width: 150px;
            max-width: max-content;

            ul {
                flex-wrap: wrap;
                width: 630px !important;

                li {
                    flex: 290px 1;
                    width: auto;
                }
            }
        }
    }

    .MuiPaper-rounded {
        min-width: 150px;
        max-width: max-content;
        // box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 13%);
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.13);
        border-radius: 10px;

        ul {
            padding: 0 1rem;
            display: flex;
            flex-wrap: wrap;
            width: 500px !important;
            justify-content: space-between;

            li {
                padding: 1rem 0;
                font-size: 14px;
                font-weight: 500;
                color: #5E6278;
                transition: 500ms;
                background-color: transparent;
                border-bottom: 1px dashed rgba(220, 220, 220, 0.3215686275);
                width: 50%;

                .icon {
                    margin-right: 1rem;
                    color: #009688;
                    font-size: 1.5rem !important;
                    width: 2.5rem;
                    line-height: initial
                }

                .bac {
                    display: flex;
                    flex-direction: column;
                    line-height: initial;

                    span {
                        color: #A1A5B7;
                        line-height: initial;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                &:hover {
                    background-color: transparent;
                    color: #009688;
                    transform: scale(1.05);

                    .icon {
                        color: #009688;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

/* Responsive Media Query */

/* Max-width 768PX */
@media (max-width: 768px) {
    .megamenu {
        .MuiPaper-rounded {
            ul {
                display: block;
                width: auto;

                li {
                    width: 100%;
                }
            }
        }
    }
}
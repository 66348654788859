@import "../../../../../App.scss";

.payments-page{
    padding: 1.5rem 0;
    background-color: #f9f9f9;

    .card {
        border-radius: 10px;
        border: 0;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
        .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
    }
}
@import "../../../../../App.scss";

.invoice_process {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .line {
      border: 3px solid black;
    }
    .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
    .card-body {
      .previous_next_btn {
        padding: 10px 20px !important;
        margin: 5px;
        border: none;
        border-radius: 4px !important;
        cursor: pointer;
        width: 200px;
        font-size: 14px !important;
        background: $theme_color;
        color: #ffffff !important;
        text-transform: capitalize !important;
        text-decoration: none;
        line-height: initial !important;
      }
      .mark_end_btn {
        padding: 10px 20px !important;
        margin: 5px;
        border: none;
        border-radius: 4px !important;
        cursor: pointer;
        width: 200px;
        font-size: 14px !important;
        background: #ffc107;
        color: #ffffff !important;
        text-transform: capitalize !important;
        text-decoration: none;
        line-height: initial !important;
        &.disabled {
          cursor: no-drop;
        }
        &.enable {
          cursor: pointer;
        }
      }
      .launch_ai_btn {
        padding: 10px 20px !important;
        margin: 5px;
        border: none;
        border-radius: 4px !important;
        cursor: pointer;
        width: 200px;
        font-size: 14px !important;
        background: $theme1;
        color: #ffffff !important;
        text-transform: capitalize !important;
        text-decoration: none;
        line-height: initial !important;
      }
      .drag_drop {
        border: 2px dashed #ccc;
        border-radius: 4px;
        padding: 2rem;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
        .default_text_dnd {
          border: none;
          &::placeholder {
            display: flex;
            justify-content: center;
            text-align: center;
            color: $text_color;
          }
        }
      }
      .reject_btn {
        padding: 10px 20px !important;
        margin: 5px;
        border: none;
        border-radius: 4px !important;
        cursor: pointer;
        width: 200px;
        font-size: 14px !important;
        background: #d91f2d;
        color: #ffffff !important;
        text-transform: capitalize !important;
        text-decoration: none;
        line-height: initial !important;
      }
      .reason_reject {
        display: flex;
        justify-content: center;
      }
      .invoice_tab {
        // margin: 50px 0;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin-bottom: 20px;
        flex-direction: column;

        .alltabcontent {
          .tab_panel {
            .card {
              border-radius: 10px;
              border: 0;
              box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
            }
          }
        }

        .back_color {
          background: #f1f1f1;
          min-width: 100%;
          border-radius: 8px;
          padding: 0px 10px;
        }
        .tab_group .tabs_link.Mui-selected {
          // color: #ffffff;
          // background: linear-gradient(
          //   86.33deg,
          //   #18bad4 1.51%,
          //   #31b680 52.02%,
          //   #80c242 99.48%
          // );
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }
        .tabs_link {
          font-weight: 500;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.58);
          text-transform: capitalize;
          padding: 0;
        }
        .MuiTabs-indicator {
          background: linear-gradient(
            86.33deg,
            #18bad4 1.51%,
            #31b680 52.02%,
            #80c242 99.48%
          );
        }
        .MuiTabs-flexContainer {
          gap: 40px;
        }
      }
    }
  }
  .invoice_head {
    text-align: center;
    background: $theme_color;
    color: white;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .img_viewer{
    background: #f1f1f1;
    text-align: center;
    color: rgba(0, 0, 0, 0.58);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}

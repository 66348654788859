@import "../../../../../App.scss";

.financial_dashboard {
  padding: 1.5rem 0;
  background-color: #f9f9f9;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.11);
    .line {
      border: 3px solid black;
    }
    .form-neselect {
      .select__control {
        .select__value-container {
          max-height: 45px;
          overflow-y: auto;
        }
      }
    }
  }
  .table_head {
    background: $theme_color;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    margin-bottom: 20px;
  }

  table {
    border: 1px solid #dee2e6;
    thead {
      tr {
        background: linear-gradient(90deg, #1ebec826 -7.19%, #86c65a30 105.94%);
        th {
          padding: 7px !important;
          font-weight: 600;
          font-size: 16px;
          background-color: transparent;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 7px !important;
          font-weight: 500;
          font-size: 15px;
        }
      }
    }
  }
}

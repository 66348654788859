@import "../../../../App.scss";

.student-allergenform-page{
    padding: 80px 0;
    .ocsp-logo{
        text-align: center;
        img{
            width: 250px;
            height: auto;
        }
    }
    .allergenform-box{
        background: #fff9f9;
        border: 1px solid #dedede;
        padding: 30px 0;
        border-radius: 8px;
        margin-top: 50px;

        .grey-box{
            padding: 20px 30px;
            p{
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
                border-bottom: 1px solid #ddd;
                padding-bottom: 15px;
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
        .student-details-block{
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            padding: 20px 30px;
            label{
                font-size: 16px;
            }
            .meal-checkbox{
                padding: 20px 0;
                border-bottom: 1px solid #ddd;
                .meal-group{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 20px;
                }
            }
            .student-radio-group{
                padding: 20px 0;
                .rado-group{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 20px;
                    margin-bottom: 7px;
                }
            }
            .parents-details-box{
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                padding: 20px 0;
                .parent-sign{
                    background-color: #ddd;
                }
            }
            .food-block-box{
                border-top: 1px solid #ddd;
                padding: 20px 0;
                .radio-group{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                }
                .milk-checkbox{
                    .meal-group{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        padding: 10px 0 20px;
                        justify-content: center;
                    }
                }
                .attechment-block{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 1000px;
                    gap: 30px;
                    padding-top: 25px;

                    .grey-btn{
                        border: none;
                        background-color: rgb(155, 155, 155);
                        padding: 12px 15px;
                        border-radius: 8px;
                        color: #fff;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        cursor: pointer;
                        transition: 0.4s all;
                        text-transform: capitalize;
                    }
                    .submit-btn{
                        border: none;
                        background-color: green;
                        padding: 10px 15px;
                        border-radius: 8px;
                        color: #fff;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        cursor: pointer;
                        transition: 0.4s all;
                        text-transform: capitalize;
                    }
                    .attach-btn{
                        border: none;
                        background-color: rgb(63, 60, 243);
                        padding: 10px 10px;
                        border-radius: 8px;
                        color: #fff;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        cursor: pointer;
                        transition: 0.4s all;
                        margin-top: 10px;
                        text-transform: capitalize;
                    }
                }
            }
        }
        .declration-block {
            padding: 20px 30px;
            .border-input{
                border: none;
                border-bottom: 1px solid #dddd;
                background: transparent;
                border-radius: 0;
            }
            .parent-sign{
                background-color: #ddd;
            }
            .bottom-text{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px 0;
                .form-number{
                    color: rgb(104 104 183);
                }
            }
            .btn-group{
                .submit-btn{
                    border: none;
                    background-color: green;
                    padding: 14px 20px;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    cursor: pointer;
                    transition: 0.4s all;
                }
                .reset-btn{
                    border: none;
                    background-color: red;
                    padding: 14px 20px;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    cursor: pointer;
                    transition: 0.4s all;
                }
            }
        }
    }
}

@media (max-width: 991px){
    .student-allergenform-page .allergenform-box .student-details-block .food-block-box .attechment-block{
        width: auto;
    }
}

@media (max-width: 768px){
    .student-allergenform-page {
        padding: 40px 0;
    }
    .student-allergenform-page .allergenform-box .student-details-block label {
        font-size: 14px;
    }
    .student-allergenform-page .allergenform-box .grey-box {
        padding: 10px 15px;
    }
    .student-allergenform-page .allergenform-box .grey-box p {
        font-size: 14px;
        padding-bottom: 10px;
    }
}

@media (max-width: 480px){
    .student-allergenform-page .allergenform-box{padding: 20px 0;}
    .student-allergenform-page .allergenform-box .student-details-block {
        padding: 15px;
    }
    .student-allergenform-page .allergenform-box .student-details-block .meal-checkbox .meal-group {
        gap: 0px;
    }
    .student-allergenform-page .allergenform-box .student-details-block .food-block-box .attechment-block {
        flex-wrap: wrap;
        gap: 10px;
    }
    .student-allergenform-page .allergenform-box .student-details-block .food-block-box .attechment-block .attach-btn{
        margin-top: 0;
        margin-left: 10px;
    }
    .student-allergenform-page .ocsp-logo img {
        width: 200px;
        margin-bottom: 20px;
    }
}

@media (max-width: 380px){
    .student-allergenform-page .allergenform-box .student-details-block .food-block-box .attechment-block .attach-btn{
        margin-top: 10px;
        margin-left: 0;
    }
}
.countBox {
    border-radius: 16px;
    text-align: left;
    padding: 28px 20px;

    .m_num {
        margin-top: 20px;
        color: #000;
        font-size: 30px;
        font-weight: 600;

        span {
            color: #464646;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .m_name {
        color: #425166;
        font-size: 16px;
        font-weight: 500;
    }

    &.darkPink {
        background-color: #FFE2E5;
    }

    &.pink {
        background: #F3E8FF;
    }

    &.green {
        background: #DCFCE7;
    }

    &.blue {
        background: #DCF3FC;
    }
}
@import "../../../../../App.scss";

.react-datepicker-wrapper {
  position: relative;
  input {
    width: 100%;
    height: 38px;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  }
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0 !important;
  right: 10px;
  top: 15px;
}
.react-datepicker__view-calendar-icon input {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  height: 46px;
}
.react-datepicker__calendar-icon path {
  fill: #808080;
}

.modal-pop {
  .addlog-popup {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    width: 724px;
    padding: 30px 30px;
    .add-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 27px;
      h5 {
        background: linear-gradient(
          86.33deg,
          #18bad4 1.51%,
          #31b680 52.02%,
          #80c242 99.48%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 400;
        font-size: 20.7246px;
        line-height: 43px;
      }
    }
    input {
      border-bottom: 1px solid #3f4254 !important;
      border: none;
      border-radius: 0;
      padding: 0;
    }
    .form-group {
      display: flex;
      align-items: center;
      gap: 30px;
      label {
        width: 155px;
        font-weight: 400;
        font-size: 16px;
        line-height: 43px;
      }
      textarea {
        border: 1px solid rgba(0, 0, 0, 0.26);
        border-radius: 0;
      }
    }
  }
}

.create-btn {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
